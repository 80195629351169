package pages

import UserContext
import anyword.model.WordEntry
import anyword.yandex.YandexComponent
import io.ktor.client.request.*
import kotlinx.coroutines.launch
import mui.icons.material.ArrowBack
import mui.icons.material.Visibility
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.router.useNavigate
import react.router.useOutletContext
import web.cssom.*
import kotlin.random.Random

external interface ReviewPageProps: Props {
    var reviewEntries: List<WordEntry>
}

val ReviewPage = FC<Props> { props ->
    val nav = useNavigate()
    val context = useOutletContext<LearnContext>()
    val userContext = useContext(UserContext)!!
    val entries = context.entries!!
    val max by useState(entries.size)
    var index by useState(pickWordIndex(entries, null))
    var show by useState(false)
    AppBar {
        //css { maxWidth = 40.em }
        position = AppBarPosition.fixed
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                color = IconButtonColor.inherit
                onClick = { nav.invoke("..") }
                ArrowBack()
            }
            Typography {
                variant = TypographyVariant.h6
                sx { flexGrow = number(1.0) }
                +"Review words"
            }
        }
    }
    Toolbar {}
    LinearProgress {
        variant = LinearProgressVariant.determinate
        value = (max - entries.size) * 100 / max
    }
    val wordEntry = entries[index]
    Card {
        sx { maxWidth = 40.em }
        CardHeader {
            title = ReactNode(wordEntry.word)
        }
        CardActions {
            sx { justifyContent = JustifyContent.center }
            Button {
                color = ButtonColor.success
                variant = ButtonVariant.contained
                + "Know"
                onClick = {
                    mainScope.launch {
                        val resp = client.post("$apiUrl/learn/know") {
                            parameter("id", wordEntry.id)
                        }
                        resp.status // TODO check
                    }
                    val newEntries = entries.filter { it.id != wordEntry.id }
                    if (newEntries.isEmpty()) {
                        context.setEntries(null)
                        nav.invoke("..")
                    } else {
                        context.setEntries(newEntries)
                        show = false
                        index = pickWordIndex(newEntries, null)
                    }
                }
            }
            Button {
                color = ButtonColor.error
                variant = ButtonVariant.contained
                + "Forgot"
                onClick = {
                    if (show) {
                        show = false
                        index = pickWordIndex(entries, index)
                        mainScope.launch {
                            val resp = client.post("$apiUrl/learn/forgot") {
                                parameter("id", wordEntry.id)
                            }
                            resp.status // TODO check
                        }
                    } else {
                        show = true
                    }
                }
            }
        }
        CardActions {
            IconButton {
                disabled = show
                Icon {
                    Visibility()
                }
                onClick = { show = true }
            }
        }
        Collapse {
            `in` = show
            CardContent {
                Typography {
                    variant = TypographyVariant.h6
                    + wordEntry.translations.joinToString()
                }
                Typography {
                    variant = TypographyVariant.body2
                    + wordEntry.transcription
                }
                renderExamples(wordEntry.examples)
            }
        }
    }
    YandexComponent {
        entry = wordEntry
    }
}

/** @param prevIndex ignore this index */
private fun pickWordIndex(newEntries: List<WordEntry>, prevIndex: Int?): Int {
    if (newEntries.size==1) return 0
    return if (prevIndex != null) {
        val i = Random.nextInt(newEntries.size-1)
        if (i < prevIndex) i else i+1
    } else {
        Random.nextInt(newEntries.size)
    }
}