package anyword.model

import kotlinx.datetime.Instant
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
class Share (
    val owner: String,
    @Contextual val time: Instant,
    val users: List<String>,
    val words: List<String>,
    @SerialName("_id") @Contextual var id: Id? = null
)