package pages

import OutletContext
import anyword.model.*
import anyword.ui.printSourceRef
import chartjs.Chart
import components.LoginIndicator
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import js.core.jso
import kotlinx.coroutines.launch
import kotlinx.datetime.*
import mui.icons.material.Menu
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.canvas
import react.dom.html.ReactHTML.div
import react.router.Outlet
import react.router.useNavigate
import react.router.useOutletContext
import web.cssom.em
import web.cssom.number
import web.html.HTMLElement
import kotlin.js.Date
import kotlin.math.min

external interface LearnContext: OutletContext {
    var entries: List<WordEntry>?
    var setEntries: (List<WordEntry>?) -> Unit
}

val LearnBasePage = FC<Props> {
    var currentEntries by useState<List<WordEntry>?>(null)
    val ctx = useOutletContext<OutletContext>()
    Outlet {
        context = jso<LearnContext> {
            openDrawer = ctx.openDrawer
            entries = currentEntries
            setEntries = { currentEntries = it }
        }
    }
}

val LearnPage = FC<Props>() {
    var version by useState<Int>(0)
    var result by useState<LearnResult?>(null)
    var snackMessage by useState<String?>(null)
    val nav = useNavigate()
    useEffect(version) {
        mainScope.launch {
            val offset = -Date().getTimezoneOffset()
            val resp = client.get("$apiUrl/learn") {
                parameter("tzOffset", offset)
            }
            if (resp.status == HttpStatusCode.OK) {
                result = resp.body<LearnResult>()
            } else {
                nav("/")
            }
        }
    }
    val context = useOutletContext<LearnContext>()
    val res = result
    var startOpen by useState(false)
    var learnOpen by useState(false)
    Fragment {
        AppBar {
            //css { maxWidth = 40.em }
            position = AppBarPosition.fixed
            Toolbar {
                IconButton {
                    edge = IconButtonEdge.start
                    color = IconButtonColor.inherit
                    onClick = { context.openDrawer() }
                    Menu()
                }
                Typography {
                    variant = TypographyVariant.h6
                    sx { flexGrow = number(1.0) }
                    +"Learn"
                }
                LoginIndicator {}
            }
        }
        Toolbar {}
    }
    if (res == null) {
        CircularProgress {}
    } else {
        Card {
            sx { maxWidth = 40.em }
            CardHeader {
                title = ReactNode("Pending")
                subheader = ReactNode("total ${res.list.size}")
            }
            CardContent {
                + res.list.map { it.word }.joinToString(limit = 20)
            }
            CardActions {
                Button {
                    variant = ButtonVariant.contained
                    disabled = res.list.isEmpty()
                    if (context.entries == null) {
                        onClick = {
                            startOpen = true
                        }
                        +"Learn"
                    } else {
                        onClick = { nav.invoke("review") }
                        +"Continue"
                    }
                }
                Button {
                    variant = ButtonVariant.contained
                    color = ButtonColor.secondary
                    + "Add to Learn"
                    onClick = {
                        learnOpen = true
                    }
                }
            }
        }
        LearnCard {
            this.result = result
        }
        if (learnOpen) {
            LearnAddDialog {
                onClose = { learnOpen = false }
                onSelect = { list: List<IdEntry> ->
                    snackMessage = "Added ${list.map { it.word }.joinToString()}"
                    learnOpen = false
                    version += 1
                }
            }
        }
        if (startOpen) {
            LearnStartDialog {
                list = res.list
                onClose = {
                    startOpen = false
                }
                onSelect = { selectedList ->
                    startOpen = false
                    context.setEntries(selectedList)
                    nav.invoke("review");
                }
            }
        }
        Snackbar {
            open = snackMessage != null
            message = ReactNode(snackMessage)
            autoHideDuration = 6000
            onClose = { _,_ ->
                snackMessage = null
            }
            Alert {
                severity = AlertColor.success
                +snackMessage
            }
        }
    }
}

external interface LearnAddDialogProps: Props {
    var onClose: () -> Unit
    var onSelect: (List<IdEntry>) -> Unit
}

private val LearnAddDialog = FC<LearnAddDialogProps> { props ->
    var result by useState<LearnAddResult?>(null)
    var wordsByChapter by useState<Map<Id,Set<String>>?>(null)
    var wordCount by useState(0)
    var selChapters by useState(emptySet<Id>())
    var maxWords by useState(0)
    fun pickWords(sel: Set<Id>, map: Map<Id,Set<String>>, all: Set<String>): Set<String> {
        return if (sel.isEmpty()) return all
        else sel.flatMap { map.getValue(it) }.toSet()
    }
    useEffectOnce {
        mainScope.launch {
            val resp = client.get("$apiUrl/learn/addList")
            val res: LearnAddResult = resp.body()
            result = res
            val wbc = res.wordChapters.flatMap { it.value.map { v -> v to it.key } }
                .groupBy({ it.first }) { it.second }.mapValues { it.value.toSet() }
            wordsByChapter = wbc
            maxWords = pickWords(emptySet(), wbc, res.wordChapters.keys).size
            wordCount = min(10, res.wordChapters.size)
        }
    }
    Dialog {
        open = true
        onClose = { _, _ -> props.onClose() }
        DialogTitle {
            +"Pick Words to Add"
        }
        DialogContent {
            val res = result
            if (res == null) {
                CircularProgress {}
            } else {
                Slider {
                    //defaultValue = min(20, res.list.size)
                    sx { marginTop = 2.em }
                    min = 0
                    max = maxWords
                    valueLabelDisplay = "on"
                    value = wordCount
                    onChange = { _, v, _ -> wordCount = v }
                }
                List {
                    for (source in res.sources) {
                        for (chapter in source.chapters) {
                            ListItem {
                                val count = wordsByChapter!![chapter.id!!]?.size ?: 0
                                ListItemIcon {
                                    Checkbox {
                                        checked = chapter.id in selChapters
                                        onClick = {
                                            val newSelChapters = if (chapter.id in selChapters) selChapters - chapter.id!!
                                            else selChapters + chapter.id!!
                                            selChapters = newSelChapters
                                            val newMax = pickWords(newSelChapters, wordsByChapter!!, res.wordChapters.keys).size
                                            maxWords = newMax
                                            wordCount = min(wordCount, newMax)
                                        }
                                    }
                                    + "($count)"
                                }
                                ListItemText {
                                    + printSourceRef(source.source, chapter.part, chapter.number, null)
                                }
                            }
                        }
                    }
                }
            }
        }
        DialogActions {
            Button {
                + "Add"
                variant = ButtonVariant.contained
                disabled = wordCount==0
                onClick = {
                    val candidateWords = if (selChapters.isEmpty()) null else pickWords(selChapters, wordsByChapter!!, result!!.wordChapters.keys)
                    mainScope.launch {
                        val resp = client.post("$apiUrl/words/learn/addMany") {
                            parameter("count", wordCount)
                            candidateWords?.forEach { parameter("ids", it) }
                        }
                        val list: List<IdEntry> = resp.body()
                        props.onSelect(list)
                    }
                }
            }
        }
    }
}

external interface LearnStartDialogProps: Props {
    var list: List<WordEntry>
    var onClose: () -> Unit
    var onSelect: (List<WordEntry>) -> Unit
}

private val LearnStartDialog = FC<LearnStartDialogProps> { props ->
    fun pickList(oldOnly: Boolean): List<WordEntry> {
        return props.list.filter {
            val r = it.learn.reviewCount ?: 0
            oldOnly && r>=4 || !oldOnly
        }
    }
    var oldOnly by useState(false)
    var filteredList by useState(pickList(oldOnly))
    var wordCount by useState(min(10,props.list.size))

    Dialog {
        open = true
        onClose = { _,_ -> props.onClose() }
        DialogTitle {
            + "Pick Words to Learn"
        }
        DialogContent {
            Slider {
                //defaultValue = min(20, res.list.size)
                sx { marginTop = 2.em }
                min = 0
                max = filteredList.size
                valueLabelDisplay = "on"
                value = wordCount
                onChange = { _,v,_ -> wordCount = v }
            }
            FormControlLabel {
                label = ReactNode("Old only")
                control = Checkbox.create {
                    checked = oldOnly
                    onChange = {_,v ->
                        oldOnly = v
                        filteredList = pickList(v)
                    }
                }
            }
        }
        DialogActions {
            Button {
                + "Start"
                variant = ButtonVariant.contained
                disabled = filteredList.isEmpty()
                onClick = {
                    props.onSelect(filteredList.take(wordCount))
                }
            }
        }
    }
}

external interface LearnCardProps: Props {
    var result: LearnResult?
}

private val LearnCard = FC<LearnCardProps> { props ->
    val canvasRef = useRef<HTMLElement>(null)
    val chartRef = useRef<Chart>(null)

    useEffect(props.result) {
        val canvas = canvasRef.current ?: return@useEffect
        val res = props.result ?: return@useEffect
        val today = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).date

        val labels = mutableListOf<String>()
        labels += "Pending"
        labels += (0..7).map { i ->
            if (i==0) "Today" else today.plus(i, DateTimeUnit.DAY).let { "${it.monthNumber}/${it.dayOfMonth}" }
        }

        val valueSets = mutableListOf<Array<Int>>()
        for (progress in anyword.model.LearnProgress.entries) {
            val values = mutableListOf<Int>()
            values += res.statsPending.byProgress[progress] ?: 0
            for (i in 0..7) {
                val d = today.plus(i, DateTimeUnit.DAY)
                val byProgress = res.statsByDate[d]?.byProgress ?: emptyMap()
                values += byProgress[progress] ?: 0
            }
            valueSets += values.toTypedArray()
        }
        println(valueSets[1])

        val entries = anyword.model.LearnProgress.entries
        if (chartRef.current == null) {
            chartRef.current = Chart(canvas, jso {
                type = "bar"
                data = jso {
                    this.labels = labels.toTypedArray()
                    datasets = valueSets.mapIndexed { i, values ->
                        jso<dynamic> {
                            label = entries[i].toString()
                            data = values
                            borderWidth = 1
                        }
                    }.toTypedArray()
                }
                options = jso {
                    scales = jso {
                        x = jso {
                            stacked = true
                        }
                        y = jso {
                            stacked = true
                        }
                    }
                }
            })
        } else {
            chartRef.current!!.let { ch: dynamic ->
                ch.data.labels = labels.toTypedArray()
                for (e in entries) {
                    ch.data.datasets[e.ordinal].data = valueSets[e.ordinal]
                }
                ch.update()
            }
        }
    }
    val res = props.result
    if (res != null) {
        Card {
            sx { maxWidth = 40.em }
            CardHeader {
                title = ReactNode("Learning")
                subheader = ReactNode("Total ${res.total}")
            }
            CardContent {
                res.next?.also { entry ->
                    val dt = entry.learn.nextReviewTime!!.toLocalDateTime(TimeZone.currentSystemDefault())
                    val hourStr = dt.hour.toString().padStart(2, '0')
                    val minuteStr = dt.minute.toString().padStart(2, '0')
                    val nextReviewTimeStr: String = "${dt.dayOfMonth}. ${dt.month.name} $hourStr:$minuteStr"
                    Typography {
                        component = div
                        variant = TypographyVariant.body1
                        +"Next: $nextReviewTimeStr"
                    }
                }
                canvas {
                    ref = canvasRef
                }
            }
        }
    }
}

internal fun Date.format(): String {
    return "${getMonth()+1}/${getDate()} ${getHours()}:${getMinutes()}"
}