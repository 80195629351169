package anyword

import web.dom.document

class DeployConfig(val googleClientId: String)

val configs = mapOf<String, DeployConfig>(
    "localhost" to DeployConfig(
        "617840153483-3akc58s59sl0hbim62k43tqumgcdhcld.apps.googleusercontent.com"
    ),
    "olegus.me" to DeployConfig(
        "617840153483-3biv2vjn36m7s7gc5bo9lgkfge4dm58h.apps.googleusercontent.com"
    )
)

val config: DeployConfig = configs.filter { document.URL.contains(it.key) }.map { it.value }.first()