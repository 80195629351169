package pages

import anyword.model.*
import anyword.yandex.YandexComponent
import components.ExampleComponent
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.coroutines.launch
import mui.icons.material.ArrowBack
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.ul
import react.router.useNavigate
import react.router.useParams
import web.cssom.number
import web.cssom.px

val Word = FC<Props>() {
    val nav = useNavigate()
    val params = useParams()
    val id = params["id"]
    var wordEntry by useState<WordEntry?>(null)
    var chapterById by useState<Map<Id,Pair<RestChapter,Source>>?>(null)
    useEffectOnce {
        mainScope.launch {
            val resp = client.get("$apiUrl/words/get/$id") {
            }
            val res: WordEntryResult = resp.body()
            wordEntry = res.word
            chapterById = res.examplesSources.flatMap { it.chapters.map { c -> c.id!! to (c to it.source) } }.associate { it }
        }
    }
    AppBar {
        position = AppBarPosition.fixed
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                size = Size.large
                sx { marginRight = 2.px }
                color = IconButtonColor.inherit
                onClick = {
                    nav.invoke("..")
                }
                ArrowBack()
            }
            Typography {
                variant = TypographyVariant.h6
                sx { flexGrow = number(1.0) }
                +(wordEntry?.word ?: "<Loading>")
            }
        }
    }
    Toolbar {} // second toolbar: one of 3 hacks to avoid hiding the toolbar in fixed placement

    val we = wordEntry
    if (we != null) {
        Typography {
            variant = TypographyVariant.body2
            + we.transcription
        }
        ul {
            for (translation in we.translations) {
                ReactHTML.li {
                    +translation
                }
            }
        }
        renderExamples(we.examples, chapterById = chapterById!!)
        YandexComponent {
            entry = we
        }
    }
}

fun ChildrenBuilder.renderExamples(
    examples: List<Example>?,
    onDelete: ((Int) -> Unit)? = null,
    chapterById: Map<Id, Pair<RestChapter, Source>> = emptyMap()
) {
    examples?.forEachIndexed { i, ex ->
        val ch = chapterById[ex.source?.chapterId]
        ExampleComponent {
            example = ex
            this.onDelete = onDelete?.let { { it.invoke(i) } }
            this.chapter = ch?.first
            this.source = ch?.second
        }
    }
}
