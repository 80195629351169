package anyword.model

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable

enum class LearnProgress { Low, Medium, High }

@Serializable
class LearnStats(
    val byProgress: Map<LearnProgress,Int>
) {
    override fun toString(): String {
        return "Stats($byProgress)"
    }
}

@Serializable
class LearnResult(
    val total: Int,
    val list: List<WordEntry>,
    val next: WordEntry?,
    val statsPending: LearnStats,
    val statsByDate: Map<LocalDate,LearnStats>
)

@Serializable
class LearnAddResult(
    val wordChapters: Map<String,List<@Contextual Id>>,
    val sources: List<RestSource>
)