package pages

import OutletContext
import anyword.model.*
import anyword.ui.printSourceRef
import components.LoginIndicator
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.coroutines.launch
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import mui.icons.material.Menu
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.router.useNavigate
import react.router.useOutletContext
import web.cssom.number

val EventsPage = FC<Props>() {
    var result by useState<Pair<EventsResult,EventsResult>?>(null)
    var snackMessage by useState<String?>(null)
    val context = useOutletContext<OutletContext>()
    var tabIndex by useState(0)
    useEffectOnce {
        mainScope.launch {
            val res1: EventsResult = client.get("$apiUrl/events").body()
            val res2: EventsResult = client.get("$apiUrl/events/own").body()
            result = res1 to res2
        }
    }
    Fragment {
        AppBar {
            //css { maxWidth = 40.em }
            position = AppBarPosition.fixed
            Toolbar {
                IconButton {
                    edge = IconButtonEdge.start
                    color = IconButtonColor.inherit
                    onClick = { context.openDrawer() }
                    Menu()
                }
                Typography {
                    variant = TypographyVariant.h6
                    sx { flexGrow = number(1.0) }
                    +"Events"
                }
                LoginIndicator {}
            }
        }
        Toolbar {}
    }
    val res = result
    Tabs {
        value = tabIndex
        variant = TabsVariant.scrollable
        onChange = { _, v -> tabIndex = v }
        Tab {
            label = ReactNode("Incoming")
        }
        Tab {
            label = ReactNode("Outgoing")
        }
    }
    if (res == null) {
        CircularProgress {}
    } else {
        val (otherRes,ownRes) = res
        EventsList {
            list = if (tabIndex==0) otherRes else ownRes
        }
    }
}

internal fun extractEventTexts(
    event: Event,
    userMap: Map<String, UserInfo>,
    wordMap: Map<String, String>,
    chapterMap: Map<Id, Pair<RestChapter, RestSource>>
): Pair<String, String?> {
    val title: String
    val description: String?
    val user = userMap.getValue(event.userId)
    val dateStr = event.time.toLocalDateTime(TimeZone.currentSystemDefault()).date
    when (event) {
        is LearnStartEvent -> {
            title = "$dateStr: ${user.fullName ?: user.name} started learning ${event.words.size} words"
            val wordStr = event.words.mapNotNull { wordMap[it] }.joinToString(", ", limit = 10)
            description = wordStr
        }
        is ScanEvent -> {
            val fromStr = chapterMap[event.chapterId]?.let { (chapter,source) ->
                "from "+ printSourceRef(source.source, chapter.part, chapter.number, chapter.title)
            } ?: ""
            title = "$dateStr: ${user.fullName ?: user.name} scanned ${event.words.size} words $fromStr"
            val wordStr = event.words.mapNotNull { wordMap[it] }.joinToString(", ", limit = 10)
            description = wordStr
        }
        else -> {
            title = event::class.simpleName ?: "Unknown"
            description = null
        }
    }
    return Pair(title, description)
}

external interface EventsListProps: Props {
    var list: EventsResult
}

val EventsList = FC<EventsListProps> { props ->
    val nav = useNavigate()
    val res = props.list
    val chapterMap = res.sources.flatMap { s -> s.chapters.map { ch -> ch to s } }.associate { it.first.id!! to it }
    List {
        for (event in res.events) {
            val (title: String, description: String?) = extractEventTexts(event, res.users, res.words, chapterMap)
            ListItem {
                ListItemButton {
                    ListItemText {
                        + title
                        description?.let { secondary = ReactNode(it) }
                    }
                    onClick = {
                        nav.invoke(event.id!!.hex)
                    }
                }
            }
        }
    }
}