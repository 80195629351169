package pages

import anyword.ext.yandex.YandexResponse
import anyword.model.Example
import anyword.model.WordEntry
import anyword.model.YandexEntry
import components.WordEdit
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.coroutines.launch
import mui.icons.material.ArrowBack
import mui.material.*
import mui.material.Size
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.onChange
import react.router.useNavigate
import web.cssom.*
import kotlin.reflect.typeOf

val NewWordPage = FC<Props> {
    val nav = useNavigate()
    AppBar {
        //css { maxWidth = 40.em }
        position = AppBarPosition.fixed
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                size = Size.large
                sx { marginRight = 2.px }
                color = IconButtonColor.inherit
                onClick = {
                    nav.invoke("..")
                }
                ArrowBack()
            }
            Typography {
                variant = TypographyVariant.h6
                sx { flexGrow = number(1.0) }
                + "Add new word"
                //+(wordEntry?.word ?: "<Loading>")
            }
        }
    }
    Toolbar {}
    WordEdit {
        onClose = { entry ->
            if (entry!=null) {
                mainScope.launch {
                    val res = client.post("$apiUrl/words/new") {
                        contentType(ContentType.Application.Json)
                        setBody(entry)
                    }
                    if (res.status.isSuccess()) {
                        nav.invoke("..")
                    }
                }
            }
        }
    }
}