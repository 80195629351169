package anyword.db

import anyword.model.Event
import anyword.model.LearnStartEvent
import anyword.model.ScanEvent
import kotlinx.datetime.Instant
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass

val commonModule = SerializersModule {
    polymorphic(Event::class) {
        subclass(LearnStartEvent::class)
        subclass(ScanEvent::class)
    }
}

@Serializable
class Notification(
    @Contextual var lastTime: Instant?
)

@Serializable
class UserSubscription(
    val userId: String,
    @Contextual var lastTime: Instant? = null
) {
    override fun toString(): String {
        return "UserSubscription($userId, $lastTime)"
    }
}

@Serializable
class UserNotifications(
    var userId: String,
    var learn: Notification?,
    var share: Notification?,
    var subs: List<UserSubscription>? = null,
    var config: Config? = null
) {
    @Serializable
    /** Starting hour, count of notifications per day, and period in hours between. */
    data class Config(val hour: Int, val count: Int, val period: Int, val timeZone: String? = null)
}