package pages

import UserContext
import anyword.model.Shares
import components.WordsCloneSelectionMenu

import mui.icons.material.ArrowBack
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.router.useLoaderData
import react.router.useNavigate
import web.cssom.number
import web.cssom.px
import web.dom.Element

external interface SharePageProps: Props {
}

val SharePage = FC<SharePageProps> {props ->
    val data = useLoaderData() as Shares
    val share = data.list.single()
    val nav = useNavigate()
    val userContext = useContext(UserContext)
    var selIds by useState(emptySet<String>())
    var snackMessage by useState<Pair<Boolean,String>?>(null)

    val cloning = share.owner != userContext?.info?.id
    val ownerMap = data.users.associateBy { it.id }
    val wordMap = data.words.associateBy { it.id }

    AppBar {
        //css { maxWidth = 40.em }
        position = AppBarPosition.fixed
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                color = IconButtonColor.inherit
                sx { marginRight = 2.px }
                onClick = {
                    nav.invoke("..")
                }
                ArrowBack()
            }
            Typography {
                variant = TypographyVariant.h6
                sx { flexGrow = number(1.0) }
                val owner = ownerMap[share.owner]
                +"From ${owner?.fullName}"
            }
            WordsCloneSelectionMenu {
                this.selIds = selIds
                this.refresh = { ok, message ->
                    if (ok) {
                        selIds = emptySet()
                    }
                    snackMessage = ok to message
                }
            }
        }
    }
    Toolbar {}

    List {
        dense = true
        for (wordId in share.words) {
            val entry = wordMap[wordId]!!
            ListItem {
                disablePadding = true
                if (cloning) {
                    ListItemIcon {
                        Checkbox {
                            //edge = SwitchBaseEdge.start
                            checked = entry.id in selIds
                            onClick = { e ->
                                if (entry.id in selIds) selIds -= entry.id!!
                                else selIds += entry.id!!
                            }
                        }
                    }
                }
                ListItemButton {
                    dense = true
                    onClick = { nav.invoke("${entry.id}") }
                    ListItemText {
                        sx { margin = 0.px }
                        primary = ReactNode(entry.word)
                        secondary = Fragment.create {
                            + entry.translations.joinToString(", ")
                        }
                    }
                }
            }
        }
    }
    Snackbar {
        open = snackMessage != null
        message = ReactNode(snackMessage?.second)
        autoHideDuration = 8000
        onClose = { _,_ ->
            snackMessage = null
        }
        Alert {
            severity = if (snackMessage?.first==true) AlertColor.success else AlertColor.error
            +snackMessage?.second
        }
    }
}
