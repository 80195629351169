package anyword.model

import anyword.db.UserSubscription
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable

@Serializable
class IdEntry(val _id: String, val word: String)

@Serializable
class StatusEntry(val _id: LearnStatus, val count: Int)

@Serializable
class WordEntryResult(
    val word: WordEntry,
    val examplesSources: List<RestSource>
)

@Serializable
class Shares(val list: List<Share>, val users: List<UserInfo>, val words: List<WordEntry>)

@Serializable
data class TokenMatch(val i: Int, val j:Int?)

@Serializable
class ChapterResponse(
    val chapter: SourceChapter,
    val source: Source
)

@Serializable
class TranslationResult(
    val srcChapter: SourceChapter,
    val dstChapter: SourceChapter,
    val srcTokens: ChapterTokens,
    val dstTokens: ChapterTokens,
    val matches: Map<Int,List<TokenMatch>>
)

@Serializable
class ExtractionResult(
    /** lemmas of words */
    val keys: List<String>,
    /** list of tokens per paragraph */
    val tokens: ChapterTokens,
    /** existing user entries for lemmas */
    val userEntries: List<WordEntry>
)

@Serializable
class ScanImport(
    @Contextual val chapterId: Id,
    val newEntries: List<WordEntry>,
    /** WordEntry Id to its new examples*/
    val newExamples: List<Pair<@Contextual Id,List<Example>>>,
    val existingWords: List<String>
)

@Serializable
class SubsResult(
    val list: List<UserSubscription>,
    /** user id to name */
    val users: Map<String,String>
)

@Serializable
class EventsResult(
    val events: List<Event>,
    val subs: List<UserSubscription>,
    /** ID to word */
    val words: Map<String, String>,
    val users: Map<String, UserInfo>,
    val sources: List<RestSource>
)

@Serializable
class EventResult(
    val event: Event,
    val user: UserInfo,
    val words: Map<String, WordEntry>,
    val chapters: Map<@Contextual Id,SourceChapter>,
    val source: Map<@Contextual Id,Source>
)