package pages

import OutletContext
import anyword.model.*
import anyword.title
import anyword.yandex.YandexDialog
import emotion.react.css
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.utils.io.*
import kotlinx.coroutines.launch
import mui.icons.material.LibraryBooks
import mui.icons.material.Search
import mui.icons.material.Translate
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.p
import react.router.NavigateOptions
import react.router.useNavigate
import react.router.useOutletContext
import react.router.useParams
import web.cssom.Color
import web.cssom.NamedColor
import web.cssom.em
import web.cssom.number
import web.dom.Element

val ChapterPage = FC<Props> {
    val context = useOutletContext<OutletContext>()
    val nav = useNavigate()
    val params = useParams()
    val chapterId = params["chapterId"]
    var restObj by useState<ChapterResponse?>(null)
    var translationResult by useState<TranslationResult?>(null)
    var menuAnchor by useState<Element?>(null)
    var loading by useState<Float?>(null)
    useEffectOnce {
        mainScope.launch {
            val resp = client.get("$apiUrl/sources/chapter/$chapterId")
            restObj = resp.body()
        }
    }
    AppBar {
        //css { maxWidth = 40.em }
        position = AppBarPosition.fixed
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                color = IconButtonColor.inherit
                onClick = { nav.invoke("/sources/${restObj?.chapter?.sourceId?.hex}") }
                mui.icons.material.ArrowBack()
            }
            Typography {
                variant = TypographyVariant.h6
                sx { flexGrow = number(1.0) }
                + (restObj?.let { it.chapter.title(it.source.type) } ?: "<Loading>")
            }
            IconButton {
                edge = IconButtonEdge.end
                color = IconButtonColor.inherit
                onClick = { ev -> menuAnchor = ev.currentTarget }
                mui.icons.material.MoreVert()
            }
            Menu {
                open = menuAnchor!=null
                anchorEl = { menuAnchor.asDynamic() }
                onClose = { menuAnchor = null }
                MenuItem {
                    ListItemIcon {
                        LibraryBooks()
                    }
                    ListItemText {
                        +"Words"
                    }
                    onClick = {
                        menuAnchor = null
                        nav.invoke("words")
                    }
                }
                MenuItem {
                    ListItemIcon {
                        Translate()
                    }
                    ListItemText {
                        +"Translate"
                    }
                    onClick = {
                        mainScope.launch {
                            client.prepareGet("$apiUrl/translate/$chapterId").execute { resp ->
                                val channel: ByteReadChannel = resp.body()
                                var line = channel.readUTF8Line()
                                while (line != null) {
                                    if (line[0].isDigit()) {
                                        loading = json.decodeFromString(line)
                                    } else {
                                        loading = null
                                        translationResult = json.decodeFromString(line)
                                        break
                                    }
                                    line = channel.readUTF8Line()
                                }
                            }
                            menuAnchor = null
                        }
                    }
                }
                MenuItem {
                    ListItemIcon {
                        Search()
                    }
                    ListItemText {
                        +"Scan"
                    }
                    onClick = {
                        menuAnchor = null
                        nav.invoke("scan")
                    }
                }
            }
        }
    }
    Toolbar {}

    var vocabWord: String? by useState(null)
    val paragraphs = restObj?.chapter?.paragraphs
    if (paragraphs != null) {
        val clickFun: (Int, Token) -> Unit = { iPar,t ->
            println("${t.lemma} ${t.type}")
            if (translationResult != null) {
                println(translationResult?.dstChapter?.paragraphs?.get(iPar)?.content)
                println(translationResult?.dstTokens?.paragraphs?.get(iPar))
            }
            vocabWord = t.lemma
        }
        val tokensByPar = translationResult?.srcTokens?.paragraphs ?: emptyMap()
        for ((iPar,par) in paragraphs.withIndex()) {
            val tokenMatch = translationResult?.matches?.get(iPar)?.associateBy { it.i } ?: emptyMap()
            val colorFun: (Int,Token) -> Color? = {iToken,v ->
                if(v.type in wordTokens) {
                    if (tokenMatch[iToken]?.j != null) NamedColor.lightgreen else NamedColor.yellow
                } else {
                    null
                }
            }
            renderParagraph(par.content, tokensByPar[iPar], colorFun, clickFun)
        }
    }
    if (vocabWord != null) {
        YandexDialog {
            isOpen = vocabWord != null
            word = vocabWord!!
            onClose = { vocabWord = null }
        }
    }
    if (loading != null) {
        Dialog {
            open = loading!=null
            DialogTitle {
                + "Loading"
            }
            DialogContent {
                LinearProgress {
                    variant = LinearProgressVariant.determinate
                    value = loading!!*100
                    sx {
                        height = 1.em
                    }
                }
            }
        }
    }
}

fun ChildrenBuilder.renderParagraph(
    parContent: String,
    parTokens: List<Token>?,
    colorFun: ((Int, Token) -> Color?)? = null,
    clickFun: ((Int, Token) -> Unit)? = null
) {
    val tokenOffsetMap = parTokens?.withIndex()?.associateBy { it.value.start }
    if (tokenOffsetMap == null) {
        p {
            +parContent
        }
    } else {
        val offsets = (listOf(0, parContent.length)
                + tokenOffsetMap.keys
                + tokenOffsetMap.map { it.key + it.value.value.text.length }
                ).distinct().sorted()
        val c = parContent
        p {
            for (i in 0 until offsets.size - 1) {
                val v = tokenOffsetMap[offsets[i]]
                val str = c.substring(offsets[i], offsets[i + 1])
                if (v != null) {
                    val clr: Color? = colorFun?.invoke(v.index, v.value)
                    ReactHTML.span {
                        css { backgroundColor = clr }
                        +str
                        if (clickFun != null) {
                            onClick = { clickFun(v.index, v.value) }
                        }
                    }
                } else {
                    +str
                }
            }
        }
    }
}