package pages

import UserContext
import anyword.model.UserInfo
import emotion.react.css
import io.ktor.client.call.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.launch
import mui.material.*
import mui.material.styles.TypographyVariant
import react.*
import react.dom.onChange
import react.router.useNavigate
import web.cssom.em
import web.cssom.px
import web.html.InputType

val RegisterPage = FC<Props> {
    val userContext = useContext(UserContext)
    val nav = useNavigate()
    var username by useState("")
    var password by useState("")
    var errorText by useState<String?>(null)
    AppBar {
        Toolbar {
            Typography {
                variant = TypographyVariant.h6
                +"New account"
            }
        }
    }
    Toolbar {}
    Stack {
        css { padding = 10.px; width = 20.em }
        TextField {
            id = "username"
            variant = FormControlVariant.outlined
            label = ReactNode("User name")
            error = errorText!=null
            helperText = ReactNode(errorText)
            value = username
            onChange = { username = it.target.asDynamic().value }
        }
        TextField {
            id = "password"
            type = InputType.password
            variant = FormControlVariant.outlined
            label = ReactNode("Password")
            value = password
            onChange = { password = it.target.asDynamic().value }
        }
        Button {
            variant = ButtonVariant.contained
            +"Register"
            onClick = {
                mainScope.launch {
                    val res = client.submitForm(
                        url = "$apiUrl/register",
                        formParameters = parameters {
                            append("username", username)
                            append("password", password)
                        }
                    )
                    if (res.status.isSuccess()) {
                        val body = res.body<UserInfo>()
                        userContext?.setInfo?.invoke(body.copy(session = res.headers["user_session"]))
                        nav("..")
                    } else {
                        errorText = res.bodyAsText()
                    }
                }
            }
        }
    }
}