package anyword.model

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/** Combination of Google and Yandex types, TODO unify */
enum class WordType {
    Punctuation, Noun, Pronoun, Verb, Adjective, Adverb, Preposition, Conjunction, Interjection, Article,
    Particle, // "не"
    Participle, // деепричастие
    Numeral
}

val wordTokens = listOf(
    WordType.Adjective,
    WordType.Adverb,
    WordType.Noun,
    WordType.Verb,
    WordType.Conjunction,
    WordType.Pronoun,
    WordType.Article,
    WordType.Preposition,
    WordType.Participle,
    WordType.Numeral
)

class WordDefinition(
    val word: String,
    val type: WordType?,
    val transcription: String?,
    val translations: List<Translation>
)
class Translation(
    /** translation synonyms in the target language */
    val words: List<String>
)

@Serializable
class ChapterTokens(
    @Contextual val chapterId: Id,
    val provider: String,
    val paragraphs: Map<Int,List<Token>>,
    @SerialName("_id") @Contextual var id: Id? = null
) {
    override fun toString(): String {
        return "ChapterTokens($id, chapter=$chapterId, size=${paragraphs.size})"
    }
}

/** @param start index in text, inclusive
 * @param end index in text, exclusive
 */
@Serializable
class Token(val start: Int, val text: String, val lemma: String, val type: WordType?) {
    override fun toString(): String {
        return "Token(start=$start, text=$text, lemma='$lemma', type=$type)"
    }
}
