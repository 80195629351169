package anyword.model

import kotlinx.datetime.Instant
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
abstract class Event {
    @SerialName("_id") @Contextual var id: Id? = null
    abstract val userId: String
    abstract val time: Instant
    abstract val words: List<String>
}

@Serializable
@SerialName("LearnStart")
class LearnStartEvent(
    override val userId: String,
    @Contextual override val time: Instant,
    override val words: List<String>
): Event()

@Serializable
@SerialName("Scan")
class ScanEvent(
    override val userId: String,
    @Contextual override val time: Instant,
    @Contextual val chapterId: Id,
    override val words: List<String>,
    val existingWords: List<String>
): Event()
