package components

import anyword.model.UserInfo
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.sx
import pages.apiUrl
import pages.client
import pages.mainScope
import react.*
import react.dom.onChange
import web.cssom.em

external interface ShareDialogProps: Props {
    var open: Boolean
    var onClose: () -> Unit
    var onSelect: (UserInfo) -> Unit
    var title: String
    var action: String
}

val UserSelectDialog = FC<ShareDialogProps> { props ->
    var allUsers: List<UserInfo>? by useState(null)
    var user by useState<UserInfo?>(null)
    useEffectOnce {
        mainScope.launch {
            val resp: List<UserInfo> = client.get("$apiUrl/users").body()
            allUsers = resp
        }
    }
    val all = allUsers
    Dialog {
        open = props.open && all!=null
        onClose = { _,_ -> props.onClose() }
        DialogTitle {
            + props.title
        }
        DialogContent {
            TextField {
                sx { minWidth = 10.em }
                label = ReactNode("User")
                select = true
                value = user?.id ?: ""
                onChange = {
                    val id = it.target.asDynamic().value as String?
                    user = allUsers?.firstOrNull { u -> u.id==id }
                }
                all?.forEach {
                    MenuItem {
                        value = it.id
                        + (it.fullName ?: it.name)
                    }
                }
            }
        }
        DialogActions {
            Button {
                variant = ButtonVariant.outlined
                + "Cancel"
                onClick = { props.onClose() }
            }
            Button {
                disabled = user==null
                variant = ButtonVariant.contained
                color = ButtonColor.primary
                + props.action
                onClick = {
                    props.onSelect(user!!)
                }
            }
        }
    }
}