package anyword

import anyword.model.RestChapter
import anyword.model.SourceChapter
import anyword.model.SourceType

fun partName(sourceType: SourceType, number: Int): String = when(sourceType) {
    SourceType.TvSeries -> "Season $number"
    else -> "Part $number"
}

fun RestChapter.title(sourceType: SourceType): String {
    val numStr = when (sourceType) {
        SourceType.TvSeries -> "E$number "
        else -> ""
    }
    return title?.let {numStr + it} ?: chapterName(sourceType, number)
}

fun SourceChapter.title(sourceType: SourceType): String {
    return title ?: chapterName(sourceType, number)
}

private fun chapterName(sourceType: SourceType, number: Int?): String {
    return when (sourceType) {
        SourceType.Book -> "Chapter"
        SourceType.TvSeries -> "Episode"
        SourceType.Article -> "Section"
        SourceType.Movie -> "Episode"
    } + (number?.let { " $it" } ?: "")
}
