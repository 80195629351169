package anyword.ext.yandex

import kotlinx.serialization.Serializable

@Serializable
class Mean (
    val text: String
)

@Serializable
class ExTr(
    val text: String
)

@Serializable
class Ex(
    val text: String,
    val tr: Array<ExTr>
)

@Serializable
class Tr(
    val text: String,
    val pos: String? = null, // verb, ..
    val gen: String? = null, // gender
    val num: String? = null, // "мн"
    val fr: Int? = null, // 1-10?
    val syn: Array<Tr>? = null, // synonyms in the translation language
    val mean: Array<Mean>? = null, // meanings in the original language
    val asp: String? = null, // несов
    val anm: String? = null,
    val ex: Array<Ex>? = null,
    val off: Boolean? = null
)

@Serializable
class Def(
    val text: String,
    val pos: String? = null, // verb, ..
    //val fr: Int? = null, // 1-10?
    val ts: String? = null, // transcription
    val tr: Array<Tr>, // translations
    val fl: String? = null// ?? (was were been)
) {
    override fun toString(): String {
        return "Def($text, $pos)"
    }
}

@Serializable
class Head()

@Serializable
class YandexResponse(
    val head: Head,
    val def: Array<Def>
)

