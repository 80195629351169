package anyword.yandex

import anyword.ext.yandex.Def
import anyword.model.WordEntry
import anyword.model.YandexEntry
import emotion.react.css
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.coroutines.launch
import mui.material.*
import pages.apiUrl
import pages.client
import pages.mainScope
import react.*
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import web.cssom.FontStyle
import web.cssom.FontWeight
import web.cssom.px
import web.http.fetchAsync
import kotlin.js.Promise

external interface YandexComponentProps: Props {
    var entry: WordEntry
}

val YandexComponent = FC<YandexComponentProps> {props ->
    var dialogOpen by useState(false)
    Button {
        +"Yandex"
        onClick = {dialogOpen = true}
    }
    YandexDialog {
        word = props.entry.word
        isOpen = dialogOpen
        onClose = { dialogOpen = false }
    }

}

external interface YandexDialogProps: Props {
    var word: String
    var isOpen: Boolean
    var onClose: () -> Unit
}

val YandexDialog = FC<YandexDialogProps> { props ->
    var defs: List<Def> by useState(emptyList())
    val word = props.word
    useEffect(word, props.isOpen) {
        if (props.isOpen) {
            mainScope.launch {
                val resp = client.get("$apiUrl/vocab/yandex") {
                    parameter("w", word)
                }
                val entry: YandexEntry = resp.body()
                defs = entry.def
            }
        }
    }
    var tabIndex by useState(0)
    Dialog {
        open = props.isOpen
        DialogTitle {
            + "Yandex: ${props.word}"
        }
        DialogContent {
            Tabs {
                value = tabIndex
                onChange = { _,v:Int -> tabIndex = v }
                defs.forEachIndexed { i,def ->
                    Tab {
                        value = i
                        label = ReactNode(def.pos ?: "..")
                    }
                }
            }
            for ((i,d) in defs.withIndex()) {
                div {
                    hidden = tabIndex!=i
                    YandexDefDetails {
                        def = d
                    }
                }
            }
        }
        DialogActions {
            Button {
                + "Close"
                onClick = { props.onClose() }
            }
        }
    }
}

external interface DetailsProps: Props {
    var def: Def
}
val YandexDefDetails = FC<DetailsProps> { props ->
    div {
        css { fontStyle = FontStyle.italic; padding = 10.px }
        + "[ ${props.def.ts} ]"
    }
    List {
        dense = true
        for (tr in props.def.tr) {
            ListItem {
                ListItemText {
                    primary = Fragment.create {
                        span { css { fontWeight = FontWeight.bold }; +tr.text }
                        tr.syn?.let { syn -> + (syn.map { ", ${it.text}" }.joinToString("")) }
                    }
                    secondary = Fragment.create {
                        tr.mean?.let { mean -> +mean.map { m -> m.text }.joinToString(", ") }
                        if (tr.fr!=null) {
                            +" (${tr.fr})"
                        }
                    }
                }
            }
        }
    }
}