package pages

import OutletContext
import UserContext
import anyword.config
import anyword.model.StatusEntry
import anyword.model.UserInfo
import components.LoginIndicator
import emotion.react.css
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import js.core.jso
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.datetime.TimeZone
import kotlinx.datetime.internal.JSJoda.ZoneId
import mui.icons.material.Menu
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.html.ReactHTML
import react.dom.onChange
import react.router.dom.Link
import react.router.useOutletContext
import web.cssom.em
import web.cssom.number
import web.cssom.px
import web.dom.document
import web.html.InputType

val MainPage = FC<Props>() {
    val c = useOutletContext<OutletContext>()
    val userContext = useContext(UserContext)
    var errorText by useState<String?>(null)
    var statusList by useState<List<StatusEntry>?>(null)

    fun handleCredentialResponse(response: dynamic) {
        val coded: String = response.credential
        mainScope.launch {
            val res = client.post("/api/login2") {
                header("Authorization", "Bearer $coded")
            }
            if (res.status.isSuccess()) {
                val body = res.body<UserInfo>()
                userContext?.setInfo?.invoke(body.copy(session = res.headers["user_session"]))
            } else {
                errorText = res.bodyAsText()
            }
        }
    }
    useEffectOnce {
        val dConfig = config
        val google = window.asDynamic().google
        google.accounts.id.initialize(jso {
            client_id = dConfig.googleClientId
            callback = ::handleCredentialResponse
        })
        google.accounts.id.renderButton(
            document.getElementById("google-button"),
            jso { theme = "outline"; size = "large" }  // customization attributes
        )
        return@useEffectOnce
    }
    useEffect(userContext?.info?.id) {
        if (userContext?.info?.id != null) {
            mainScope.launch {
                val list: List<StatusEntry> = client.get("$apiUrl/words/stats").body()
                statusList = list
            }
        }
    }
    AppBar {
        position = AppBarPosition.fixed
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                color = IconButtonColor.inherit
                onClick = { c.openDrawer() }
                Menu()
            }
            Typography {
                variant = TypographyVariant.h6
                sx { flexGrow = number(1.0) }
                + "Anyword"
            }
            LoginIndicator {}
        }
    }
    Toolbar {} // second toolbar: one of 3 hacks to avoid hiding the toolbar in fixed placement

    var username by useState("")
    var password by useState("")
    if (userContext?.info == null) {
        Stack {
            css { padding = 10.px; width = 20.em }
            TextField {
                id = "username"
                variant = FormControlVariant.outlined
                label = ReactNode("User name")
                error = errorText!=null
                value = username
                onChange = { username = it.target.asDynamic().value }
            }
            TextField {
                id = "password"
                type = InputType.password
                variant = FormControlVariant.outlined
                label = ReactNode("Password")
                error = errorText!=null
                helperText = ReactNode(errorText)
                value = password
                onChange = { password = it.target.asDynamic().value }
            }
            Button {
                variant = ButtonVariant.contained
                +"Submit"
                onClick = {
                    mainScope.launch {
                        val res = client.submitForm(
                            url = "$apiUrl/login",
                            formParameters = parameters {
                                append("username", username)
                                append("password", password)
                            }
                        )
                        if (res.status.isSuccess()) {
                            val body = res.body<UserInfo>()
                            userContext?.setInfo?.invoke(body.copy(session = res.headers["user_session"]))
                        } else {
                            errorText = res.bodyAsText()
                        }
                    }
                }
            }
            Link {
                to = "register"
                + "Register"
            }
            ReactHTML.div { id = "google-button" }
        }
    } else {
        val fullName = userContext.info?.fullName ?: ""
        Typography {
            variant = TypographyVariant.h6
            + "Hello $fullName"
        }
        val list = statusList
        if (list != null && list.isNotEmpty()) {
            Table {
                sx { maxWidth = 40.em }
                TableBody {
                    list.sortedBy { it._id.ordinal }.forEach {
                        TableRow {
                            TableCell { +"${it._id}" }
                            TableCell { +"${it.count}" }
                        }
                    }
                }
            }
        }
    }
}
