package anyword.ui

import anyword.model.RestChapter
import anyword.model.Source
import anyword.model.SourceChapter
import anyword.model.SourceType

fun printSourceRef(source: Source, chapter: SourceChapter): String {
    return printSourceRef(source, chapter.part, chapter.number, chapter.title)
}

fun printSourceRef(source: Source, chapter: RestChapter): String {
    return printSourceRef(source, chapter.part, chapter.number, chapter.title)
}

fun printSourceRef(source: Source, part: Int, number: Int?, title: String?): String {
    return when(source.type) {
        SourceType.TvSeries -> "${source.title} S${part}E${number}"
        else -> source.title + (number?.let { " C${it}" } ?: "")
    }
}