package pages

import anyword.model.ChapterResponse
import anyword.model.SourceChapter
import anyword.model.WordsResult
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import js.core.jso
import kotlinx.coroutines.launch
import mui.icons.material.ArrowBack
import mui.material.*
import react.*
import react.router.NavigateOptions
import react.router.useNavigate
import react.router.useParams
import remix.run.router.RelativeRoutingType

val ChapterWordsPage = FC<Props> {
    val nav = useNavigate()
    val params = useParams()
    val chapterId = params["chapterId"]
    var result by useState<WordsResult?>(null)
    var chapter by useState<SourceChapter?>(null)
    useEffectOnce {
        mainScope.launch {
            chapter = client.get("$apiUrl/sources/chapter/$chapterId").body<ChapterResponse>().chapter
            val resp = client.get("$apiUrl/words") {
                parameter("chapterId", chapterId)
            }
            if (resp.status == HttpStatusCode.OK) {
                result = resp.body<WordsResult>()
            } else {
                nav("/")
            }
        }
    }
    AppBar {
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                color = IconButtonColor.inherit
                onClick = { nav.invoke("..", jso{ relative = RelativeRoutingType.path }) }
                ArrowBack()
            }
            + "Chapter Words"
        }
    }
    Toolbar {}
    if (result != null) {
        val list = result!!.list.map {
            it to it.examples?.filter { it.source?.chapterId?.hex == chapterId }?.singleOrNull()
        }.sortedBy { it.second?.source?.iPar ?: -1 }
        List {
            dense = true
            for ((entry,example) in list) {
                ListItem {
                    key = entry.id
                    disablePadding = true
                    ListItemButton {
                        val timeStr = example?.let {
                            val time = chapter!!.paragraphs[it.source!!.iPar].time
                            val secs = time?.inWholeSeconds ?: 0
                            "${secs/60}m${secs%60}s"
                        } ?: ""
                        ListItemText {
                            primary = ReactNode("${entry.word} ($timeStr)")
                            secondary = ReactNode(entry.translations.joinToString())
                        }
                        onClick = {
                            nav.invoke(entry.id!!)
                        }
                    }
                }
            }
        }
    }
}