package components

import anyword.model.Example
import anyword.model.RestChapter
import anyword.model.RestSource
import anyword.model.Source
import anyword.title
import anyword.ui.printSourceRef
import emotion.react.css
import mui.icons.material.Close
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import web.cssom.*

external interface ExampleProps: Props {
    var example: Example
    var onDelete: (() -> Unit)?
    var selected: Boolean?
    var onSelect: (() -> Unit)?
    var source: Source?
    var chapter: RestChapter?
}

val ExampleComponent = FC<ExampleProps> { props ->
    val ex = props.example
    Paper {
        sx { maxWidth = 40.em }
        Stack {
            direction = responsive(StackDirection.row)
            if (props.onDelete != null) {
                IconButton {
                    Close {
                        fontSize = SvgIconSize.small
                    }
                    onClick = { props.onDelete?.invoke() }
                }
            } else if (props.selected != null) {
                Checkbox {
                    checked = props.selected
                    onClick = { props.onSelect!!() }
                }
            }
            div {
                Typography {
                    variant = TypographyVariant.body1
                    renderText(ex.original)
                }
                Typography {
                    variant = TypographyVariant.body2
                    renderText(ex.translation)
                }
            }
        }
        if (ex.source != null && props.chapter!=null && props.source!=null) {
            div {
                css {
                    fontSize = FontSize.small
                    textAlign = TextAlign.end
                }
                + printSourceRef(props.source!!, props.chapter!!)
            }
        }
    }
}

private fun ChildrenBuilder.renderText(s: String) {
    val lst = s.split("#")
    for ((index, v) in lst.withIndex()) {
        if (index%2==0) {
            +v
        } else {
            ReactHTML.span {
                css { textDecoration = TextDecoration.underline }
                +v
            }
        }
    }
}