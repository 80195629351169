package anyword.model

import anyword.ext.yandex.Def
import kotlinx.datetime.Instant
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
class YandexEntry(
    val word: String,
    val def: List<Def>,
    val timestamp: Instant,
    @SerialName("_id") @Contextual var id: Id? = null
) {
    override fun toString(): String {
        return "YandexEntry($word, $id, $def)"
    }
}