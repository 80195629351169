import anyword.model.Shares
import anyword.model.UserInfo
import chartjs.Chart
import components.MyDrawer
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import js.core.jso
import kotlinx.coroutines.asPromise
import kotlinx.coroutines.async
import mui.material.styles.ThemeProvider
import mui.material.styles.TypographyOptions
import mui.material.styles.TypographyVariant.Companion.h6
import mui.material.styles.createTheme
import pages.*
import react.FC
import react.Props
import react.create
import react.dom.client.createRoot
import react.router.Outlet
import react.router.RouterProvider
import react.router.dom.createBrowserRouter
import react.router.useLoaderData
import react.useState
import web.cssom.CSSStyleDeclaration
import web.cssom.rem
import web.dom.document

val Light = createTheme(
    jso {
//        palette = jso { mode = light }
        typography = TypographyOptions {
            h6 {
                fontSize = 1.0.rem
            }
        }
    }
)

fun main() {
    val container = document.createElement("div")
    document.body.appendChild(container)

    val topRouter = createBrowserRouter(arrayOf(
        jso {
            element = LayoutContainer.create()
            loader = {
                mainScope.async {
                    val res = client.get("$apiUrl/status")
                    if (res.status.isSuccess()) {
                        res.body<UserInfo>()
                    } else {
                        null
                    }
                }.asPromise()
            }
            children = arrayOf(
                jso {
                    index = true
                    element = MainPage.create()
                },
                jso {
                    path = "register"
                    element = RegisterPage.create()
                },
                jso {
                    path = "words"
                    element = WordsPage.create()
                    children = arrayOf(
                        jso {
                            path = "new"
                            element = NewWordPage.create()
                        },
                        jso {
                            path = ":id"
                            element = pages.Word.create()
                        }
                    )
                },
                jso {
                    path = "learn"
                    element = LearnBasePage.create()
                    children = arrayOf(
                        jso {
                            index = true
                            element = LearnPage.create()
                        },
                        jso {
                            path = "review"
                            element = ReviewPage.create()
                        }
                    )
                },
                jso {
                    path = "sources"
                    children = arrayOf(
                        jso {
                            index = true
                            element = SourcesPage.create()
                        },
                        jso {
                            path = ":sourceId"
                            element = SourcePage.create()
                        },
                        jso {
                            path = "chapter/:chapterId"
                            element = ChapterPage.create()
                        },
                        jso {
                            path = "chapter/:chapterId/scan"
                            element = ScanPage.create()
                        },
                        jso {
                            path = "chapter/:chapterId/words"
                            children = arrayOf(
                                jso {
                                    index = true
                                    element = pages.ChapterWordsPage.create()
                                },
                                jso {
                                    path = ":id"
                                    element = Word.create()
                                }
                            )
                        }
                    )
                },
                jso {
                    path = "upload"
                    element = UploadPage.create()
                },
                jso {
                    path = "profile"
                    element = ProfilePage.create()
                },
                jso {
                    path = "shared"
                    children = arrayOf(
                        jso {
                            index = true
                            element = SharedPage.create()
                        },
                        jso {
                            path = ":shareId"
                            children = arrayOf(
                                jso {
                                    index = true
                                    element = SharePage.create()
                                    loader = {
                                        mainScope.async {
                                            val res = client.get("$apiUrl/shares/${it.params["shareId"]}")
                                            if (res.status.isSuccess()) {
                                                res.body<Shares>()
                                            } else {
                                                null
                                            }
                                        }.asPromise()
                                    }
                                },
                                jso {
                                    path = ":id"
                                    element = Word.create()
                                }
                            )
                        }
                    )
                },
                jso {
                    path = "events"
                    children = arrayOf(
                        jso {
                            index = true
                            element = EventsPage.create()
                        },
                        jso {
                            path = ":eventId"
                            children = arrayOf(
                                jso {
                                    index = true
                                    element = EventPage.create()
                                },
                                jso {
                                    path = ":id"
                                    element = Word.create()
                                }
                            )
                        }
                    )
                }
            )
        },
    ))
    val root = ThemeProvider.create {
        this.theme = Light
        RouterProvider {
            router = topRouter
        }
    }
    createRoot(container).render(root)
}

external interface OutletContext {
    var openDrawer: () -> Unit
}

val LayoutContainer = FC<Props>() {
    var open by useState(false)
    val loadedUserInfo = useLoaderData() as UserInfo?
    var userInfo by useState(loadedUserInfo)
    UserContext.Provider {
        value = jso {
            info = userInfo
            setInfo = { userInfo = it }
        }
        MyDrawer {
            isOpen = open
            close = { open = false }
        }
        Outlet {
            context = jso<OutletContext> { openDrawer = { open = true } }
        }
    }
}
