package components

import anyword.model.Example
import anyword.model.WordEntry
import anyword.model.YandexEntry
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.coroutines.launch
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import pages.apiUrl
import pages.client
import pages.mainScope
import pages.renderExamples
import react.*
import react.dom.onChange
import web.cssom.FlexDirection
import web.cssom.em

external interface WordEditProps: Props {
    var word: String?
    var translations: List<String>?
    var transcription: String?
    var examples: List<Example>?
    var onClose: (WordEntry?) -> Unit
}

val WordEdit = FC<WordEditProps> { props ->
    var word by useState(props.word ?: "")
    var candidates by useState<List<String>?>(null)
    var transcription by useState<String?>(props.transcription)
    var translations by useState(props.translations ?: emptyList())
    var translationsOpen by useState(false)
    var examples by useState(props.examples ?: emptyList())
    useEffect(translationsOpen) {
        if (translationsOpen && word.isNotBlank()) {
            mainScope.launch {
                val res = client.get("$apiUrl/vocab/yandex") {
                    parameter("w", word)
                }
                val obj: YandexEntry = res.body()
                obj.def.firstOrNull()?.also { transcription = it.ts }
                candidates = obj.def.flatMap {
                    it.tr.flatMap { w -> listOf(w.text) + (w.syn?.map { s -> s.text } ?: emptyList()) }
                }
            }
        }
    }
    FormGroup {
        sx {
            flexDirection = FlexDirection.column
            maxWidth = 40.em
            gap = 0.5.em
        }
        TextField {
            label = ReactNode("Word")
            value = word
            onChange = { word = it.target.asDynamic().value }
        }
        @Suppress("UPPER_BOUND_VIOLATED")
        Autocomplete<AutocompleteProps<String>> {
            multiple = true
            options = candidates?.toTypedArray() ?: emptyArray()
            freeSolo = true
            onOpen = { translationsOpen = true }
            onClose = { _, _ -> translationsOpen = false }
            value = translations.toTypedArray()
            onChange = { _, v: Array<String>, _, _ ->
                translations = v.toList()
            }
            renderInput = { params ->
                TextField.create {
                    +params
                    label = ReactNode("Translations")
                }
            }
        }
        Typography {
            variant = TypographyVariant.body2
            + transcription
        }
        Typography {
            variant = TypographyVariant.h5
            + "Examples:"
        }
        var exampleOriginal by useState("")
        var exampleTranslation by useState("")
        renderExamples(examples, { i -> examples = examples.filterIndexed { j, _ -> j != i } })
        FormGroup {
            TextField {
                label = ReactNode("Original")
                value = exampleOriginal
                onChange = { exampleOriginal = it.target.asDynamic().value as String }
            }
            TextField {
                label = ReactNode("Translation")
                value = exampleTranslation
                onChange = { exampleTranslation = it.target.asDynamic().value as String }
            }
            Button {
                disabled = exampleOriginal.isBlank() || exampleTranslation.isBlank()
                + "Add example"
                onClick = {
                    examples += Example(exampleOriginal, exampleTranslation)
                    exampleOriginal = ""
                    exampleTranslation = ""
                }
            }
        }
        FormGroup {
            sx { flexDirection = FlexDirection.row }
            Button {
                disabled = word.isBlank() || translations.isEmpty()
                variant = ButtonVariant.contained
                color = ButtonColor.primary
                +"Create"
                onClick = {
                    val we = WordEntry("", word, transcription, translations, examples.ifEmpty { null })
                    props.onClose(we)
                }
            }
        }
    }

}