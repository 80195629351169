package pages

import OutletContext
import anyword.model.RestChapter
import anyword.model.RestSource
import anyword.model.SourceChapter
import anyword.partName
import anyword.title
import emotion.react.css
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import io.ktor.utils.io.js.*
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.icons.material.Menu
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import org.khronos.webgl.ArrayBuffer
import react.*
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.p
import react.dom.onChange
import react.router.useNavigate
import react.router.useOutletContext
import react.router.useParams
import web.cssom.em
import web.cssom.number
import web.cssom.px
import web.file.FileList
import web.html.InputType

val SourcePage = FC<Props> {
    val context = useOutletContext<OutletContext>()
    val nav = useNavigate()
    val params = useParams()
    val sourceId = params["sourceId"]
    var version by useState(0)
    var restObj by useState<RestSource?>(null)
    var chaptersByPart by useState<Map<Int,List<RestChapter>>>(emptyMap())
    useEffect(version) {
        mainScope.launch {
            val resp = client.get("$apiUrl/sources/$sourceId")
            val obj = resp.body<RestSource>()
            restObj = obj
            chaptersByPart = obj.chapters.groupBy { it.part }
        }
    }
    AppBar {
        //css { maxWidth = 40.em }
        position = AppBarPosition.fixed
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                color = IconButtonColor.inherit
                onClick = { context.openDrawer() }
                Menu()
            }
            Typography {
                variant = TypographyVariant.h6
                sx { flexGrow = number(1.0) }
                + (restObj?.source?.title ?: "<Loading>")
            }
        }
    }
    Toolbar {}

    var tabIndex by useState(0)
    val parts = chaptersByPart.keys.sorted()
    if (parts.isNotEmpty()) {
        Tabs {
            value = tabIndex
            onChange = { _,v -> tabIndex = v }
            parts.forEach {
                Tab { label = ReactNode(partName(restObj?.source?.type!!, it)) }
            }
        }
        List {
            for (chapter in chaptersByPart.getValue(parts[tabIndex])) {
                ListItem {
                    ListItemButton {
                        onClick = {
                            nav.invoke("../chapter/${chapter.id!!.hex}")
                        }
                        ListItemText {
                            + chapter.title(restObj?.source?.type!!)
                        }
                    }
                }
            }
        }
    }

    var showNewDialog by useState(false)
    Fab {
        color = FabColor.primary
        Icon {
            Add()
            onClick = {
                showNewDialog = true
            }
        }
    }
    var part by useState(1)
    var chapter: Int? by useState(null)
    var title by useState("")
    var files by useState<FileList?>(null)
    Dialog {
        open = showNewDialog
        DialogTitle {
            +"New chapter"
        }
        DialogContent {
            Stack {
                css { padding = 10.px }
                TextField {
                    label = ReactNode("Part")
                    value = part.toString()
                    onChange = { ev ->
                        val s = ev.target.asDynamic().value as String
                        val newNumber = s.toIntOrNull()
                        if (newNumber!=null) part = newNumber
                    }
                }
                TextField {
                    label = ReactNode("Chapter")
                    sx { minWidth = 10.em }
                    value = chapter?.toString() ?: ""
                    onChange = {
                        val s = it.target.asDynamic().value as String
                        if (s.isBlank()) chapter = null
                        else if (s.toIntOrNull()!=null) chapter = s.toInt()
                    }
                }
                TextField {
                    label = ReactNode("Title")
                    value = title
                    onChange = { ev -> title = ev.target.asDynamic().value }
                }
                input {
                    type = InputType.file
                    //hidden = true
                    accept = ".srt"
                    onChange = { ev ->
                        files = ev.target.files!!
                    }
                }
            }
        }
        DialogActions {
            Button {
                +"Close"
                onClick = { showNewDialog = false }
            }
            Button {
                variant = ButtonVariant.contained
                disabled = files==null
                +"Create"
                onClick = { ev ->
                    val file = files!![0]
                    mainScope.launch {
                        val data = file.arrayBuffer().await()
                        val res = client.post("$apiUrl/sources/$sourceId/new") {
                            setBody(MultiPartFormDataContent(
                                formData {
                                    append("part", part)
                                    if (chapter != null) append("chapter", chapter!!)
                                    if (title.isNotBlank()) append("title", title)
                                    append("file", file.name, bodyBuilder = {
                                        this.writeFully(data.asDynamic() as ArrayBuffer) // it is the same js class
                                    })
                                }
                            ))
//                            onUpload { bytesSentTotal, _ ->
//                                progressFun((bytesSentTotal*100/data.byteLength).toInt())
//                            }
                        }
                        if (res.status.isSuccess()) {
                            showNewDialog = false
                            version += 1
                        }
                    }
                }
            }
        }
    }
}