package pages

import UserContext
import anyword.model.EventResult
import anyword.ui.printSourceRef
import components.WordsCloneSelectionMenu
import emotion.react.css
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.coroutines.launch
import mui.icons.material.ArrowBack
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.router.useNavigate
import react.router.useParams
import web.cssom.em
import web.cssom.number
import web.cssom.px


val EventPage = FC<Props>() {
    val nav = useNavigate()
    val userContext = useContext(UserContext)
    val params = useParams()
    val id = params["eventId"]
    var result by useState<EventResult?>(null)
    var selIds by useState(emptySet<String>())
    var snackMessage by useState<Pair<Boolean,String>?>(null)
    useEffectOnce {
        mainScope.launch {
            val resp = client.get("$apiUrl/events/$id")
            result = resp.body()
        }
    }
    val res = result
    val t = res?.let { extractEventTexts(
        it.event,
        mapOf(it.user.id to it.user),
        it.words.mapValues { it.value.word },
        emptyMap()
    ) }
    AppBar {
        position = AppBarPosition.fixed
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                size = Size.large
                sx { marginRight = 2.px }
                color = IconButtonColor.inherit
                onClick = {
                    nav.invoke("..")
                }
                ArrowBack()
            }
            Typography {
                variant = TypographyVariant.h6
                sx { flexGrow = number(1.0) }
                +(t?.first ?: "<Loading>")
            }
            WordsCloneSelectionMenu {
                this.selIds = selIds
                this.refresh = { ok, message ->
                    if (ok) {
                        selIds = emptySet()
                    }
                    snackMessage = ok to message
                }
            }
        }
    }
    Toolbar {} // second toolbar: one of 3 hacks to avoid hiding the toolbar in fixed placement
    if (res != null) {
        val cloning = res.user.id != userContext?.info?.id
        List {
            dense = true
            for (wordId in res.words.keys) {
                val entry = res.words[wordId]!!
                ListItem {
                    disablePadding = true
                    if (cloning) {
                        ListItemIcon {
                            Checkbox {
                                //edge = SwitchBaseEdge.start
                                checked = entry.id in selIds
                                onClick = { e ->
                                    if (entry.id in selIds) selIds -= entry.id!!
                                    else selIds += entry.id!!
                                }
                            }
                        }
                    }
                    ListItemButton {
                        dense = true
                        onClick = { nav.invoke("${entry.id}") }
                        ListItemText {
                            sx { margin = 0.px }
                            val chapterId = entry.examples?.mapNotNull { s -> s.source?.chapterId }?.firstOrNull()
                            val chapter = chapterId?.let { res.chapters[it] }
                            val source = chapter?.let { c -> res.source[c.sourceId] }
                            val sourceRef = source?.let { printSourceRef(it, chapter) }
                            primary = ReactNode(entry.word + (sourceRef?.let { " ($it)" }?:""))
                            secondary = Fragment.create {
                                + entry.translations.joinToString(", ")
                            }
                        }
                    }
                }
            }
        }
    }
    Snackbar {
        open = snackMessage != null
        message = ReactNode(snackMessage?.second)
        autoHideDuration = 8000
        onClose = { _,_ ->
            snackMessage = null
        }
        Alert {
            severity = if (snackMessage?.first==true) AlertColor.success else AlertColor.error
            +snackMessage?.second
        }
    }
}
