package pages

import anyword.model.*
import components.ExampleComponent
import components.WordEdit
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.utils.io.*
import kotlinx.coroutines.launch
import mui.icons.material.ArrowBack
import mui.icons.material.Delete
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.*
import react.dom.html.ReactHTML
import react.router.useNavigate
import react.router.useParams
import web.cssom.NamedColor
import web.cssom.em
import web.cssom.px

private val statuses = arrayOf<LearnStatus?>(null) + LearnStatus.entries.toTypedArray()

private sealed class SelEntry {
    class New(val word: String): SelEntry()
    class Edit(val entry: WordEntry): SelEntry()
    class Examples(val entry: WordEntry): SelEntry()
}


val ScanPage = FC<Props> {
    val params = useParams()
    val nav = useNavigate()
    val chapterId = params["chapterId"]
    var translationResult by useState<TranslationResult?>(null)
    var restObj by useState<ExtractionResult?>(null)
    var entriesByStatus by useState<Map<LearnStatus?,Map<String,List<WordEntry>>>>(emptyMap())
    var tokensByKey by useState<Map<String,List<Pair<Int,Int>>>>(emptyMap())
    var selKey by useState<String?>(null)
    var selEntry by useState<SelEntry?>(null)
    var tabIndex by useState(0)
    var createdEntries by useState<Map<String,List<WordEntry>>>(emptyMap())
    var addedExamples by useState<Map<String,Set<Int>>>(emptyMap()) // entryId -> index in computed Examples
    var loading by useState<Float?>(0.0f)
    useEffectOnce {
        mainScope.launch {
            val resp = client.get("$apiUrl/scans/get/$chapterId")
            val res: ExtractionResult = resp.body()
            val allEntryWords = res.userEntries.map { it.word }.toSet()
            entriesByStatus = res.userEntries.groupBy { it.learn.status }.mapValues { it.value.groupBy { e -> e.word } } +
                    mapOf(null to res.keys.filter { k -> k !in allEntryWords }.associateWith { emptyList() })
            tokensByKey = res.tokens.paragraphs.flatMap { it.value.withIndex().map { (i,v) -> v.lemma to Pair(it.key, i) } }
                .groupBy({ it.first.lowercase() }, { it.second })
            restObj = res
            client.prepareGet("$apiUrl/translate/$chapterId").execute { resp1 ->
                val channel: ByteReadChannel = resp1.body()
                var line = channel.readUTF8Line()
                while (line != null) {
                    if (line[0].isDigit()) {
                        loading = json.decodeFromString(line)
                    } else {
                        loading = null
                        translationResult = json.decodeFromString(line)
                        break
                    }
                    line = channel.readUTF8Line()
                }
            }
        }
    }
    AppBar {
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                color = IconButtonColor.inherit
                onClick = { nav.invoke("..") }
                ArrowBack()
            }
            + "Scan chapter"
            Button {
                variant = ButtonVariant.contained
                color = ButtonColor.secondary
                onClick = {
                    val ignoredKeys = entriesByStatus.getValue(null).keys - createdEntries.keys
                    val ignoreEntries = ignoredKeys.map { w -> WordEntry("", w, null, emptyList(), null, Learn(LearnStatus.Ignore, null)) }
                    val entryMap = restObj!!.userEntries.associateBy { it.id!! }
                    val newExamples = addedExamples.map { (entryId,exInds) ->
                        val wordKey = entryMap.getValue(entryId).word.lowercase()
                        val examples = createExamplesForKey(wordKey, tokensByKey, translationResult!!, chapterId)
                        Id(entryId) to exInds.map { examples[it] }
                    }
                    val existingWords = addedExamples.keys.toList() // TODO currently take only from examples
                    val scanImport = ScanImport(Id(chapterId!!), createdEntries.values.flatten().toList() + ignoreEntries,
                        newExamples, existingWords)
                    mainScope.launch {
                        val res = client.post("$apiUrl/scans/import") {
                            contentType(ContentType.Application.Json)
                            setBody(scanImport)
                        }
                        println(res.status)
                        nav.invoke("..")
                    }
                }
                + "Finish"
            }
        }
    }
    Toolbar {}
    val obj = restObj
    if (obj != null && loading==null) {
        Tabs {
            value = tabIndex
            variant = TabsVariant.scrollable
            onChange = { _, v -> tabIndex = v }
            statuses.forEach {
                val text = when(it) {
                    null -> "New"
                    LearnStatus.None -> "Pending"
                    LearnStatus.Active -> "Active"
                    LearnStatus.Done -> "Done"
                    LearnStatus.Ignore -> "Ignore"
                }
                val count = entriesByStatus[it]?.size ?: 0
                Tab { label = ReactNode("$text($count)") }
            }
        }
        val learnStatus = statuses[tabIndex]
        val wordMap = entriesByStatus[learnStatus] ?: emptyMap()
        List {
            dense = true
            sx { maxHeight = 400.px }
            for (wordKey in wordMap.keys) {
                val entries = wordMap[wordKey.lowercase()]
                val tokens = tokensByKey[wordKey]?.mapNotNull { obj.tokens.paragraphs[it.first]?.get(it.second) } ?: emptyList()
                val title = tokens.map { it.lemma }.distinct().joinToString()
                ListItem {
                    key = wordKey
                    disablePadding = true
                    ListItemButton {
                        onClick = {
                            selKey = wordKey
                        }
                        ListItemIcon {
                            if (wordKey in createdEntries) {
                                mui.icons.material.CheckCircle()
                            } else if (entries != null) {
                                val addedCount = entries.map { addedExamples[it.id!!]?.size ?: 0 }.sum()
                                addedExamplesIcon(addedCount)
                            }
                        }
                        ListItemText {
                            sx { margin = 0.px }
                            primary = ReactNode(title)
                            if (entries!=null) {
                                secondary = ReactNode(entries.flatMap { it.translations }.joinToString())
                            }
                        }
                    }
                }
            }
        }
        if (selKey != null) {
            // choose new entry or add examples
            val keyEntries = entriesByStatus.values.mapNotNull { it[selKey!!] }.flatten() + createdEntries.getOrElse(selKey!!) { emptyList() }
            Dialog {
                open = selKey != null
                onClose = { _, _ -> selKey = null }
                DialogTitle {
                    +"Create or Edit"
                }
                DialogContent {
                    List {
                        for (e in keyEntries) {
                            ListItem {
                                val added = addedExamples.get(e.id)?.size ?: 0
                                ListItemButton {
                                    ListItemIcon {
                                        addedExamplesIcon(added)
                                    }
                                    ListItemText {
                                        +e.word
                                        secondary = ReactNode(e.translations.joinToString(", "))
                                    }
                                    onClick = {
                                        selEntry = if (e.id==null) SelEntry.Edit(e) else SelEntry.Examples(e)
                                        selKey = null
                                    }
                                }
                                if (e.id==null) {
                                    ListItemSecondaryAction {
                                        IconButton {
                                            Delete()
                                            onClick = {
                                                val editKey = selKey!!
                                                val entryList: List<WordEntry> = createdEntries.getOrElse(editKey) { emptyList() }.filter { it != e }
                                                createdEntries = createdEntries + (editKey to entryList)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                DialogActions {
                    Button {
                        variant = ButtonVariant.contained
                        +"New"
                        onClick = {
                            selEntry = SelEntry.New(selKey!!)
                            selKey = null
                        }
                    }
                }
            }
        } else if (selEntry != null) {
            val se = selEntry!!
            val (editKey, editEntry) = when (se) {
                is SelEntry.New -> se.word to null
                is SelEntry.Edit -> se.entry.word to se.entry
                is SelEntry.Examples -> se.entry.word to se.entry
            }
            if (se is SelEntry.New || se is SelEntry.Edit) {
                // new or edit new word
                Dialog {
                    open = true
                    onClose = {_,_ -> selEntry = null }
                    DialogContent {
                        val initExamples = createExamplesForKey(editKey, tokensByKey, translationResult!!, chapterId)
                        WordEdit {
                            word = editKey
                            translations = editEntry?.translations
                            transcription = editEntry?.transcription
                            examples = editEntry?.examples ?: initExamples
                            onClose = { entry ->
                                if (entry != null) {
                                    val entryList: List<WordEntry> = createdEntries.getOrElse(editKey) { emptyList() }.filter { it != editEntry }
                                    createdEntries = createdEntries + (editKey to entryList + entry)
                                }
                                selEntry = null
                            }
                        }
                    }
                }
            } else {
                // edit examples of existing word
                val initExamples = createExamplesForKey(editKey, tokensByKey, translationResult!!, chapterId)
                val wordEntries = wordMap.getValue(editKey)
                AddExamplesDialog {
                    wordKey = editKey
                    onClose = {
                        addedExamples += it
                        selEntry = null
                    }
                    entries = listOf(editEntry!!)
                    examples = initExamples
                    selSets = wordEntries.map { it.id!! }.associateWith { addedExamples.getOrElse(it) { emptySet() } }
                }
            }
        }
    }
    if (loading != null) {
        Dialog {
            open = loading!=null
            DialogTitle {
                + "Loading"
            }
            DialogContent {
                LinearProgress {
                    variant = LinearProgressVariant.determinate
                    value = loading!!*100
                    sx {
                        height = 1.em
                    }
                }
            }
        }
    }
}

private fun ChildrenBuilder.addedExamplesIcon(addedCount: Int) {
    if (addedCount > 0) {
        Badge {
            badgeContent = ReactNode("$addedCount")
            mui.icons.material.Add()
        }
    }
}

private fun createExamplesForKey(
    selKey: String?,
    tokensByKey: Map<String, List<Pair<Int, Int>>>,
    translationResult: TranslationResult,
    chapterId: String?
): List<Example> {
    val selTokens = tokensByKey.getValue(selKey!!)
    val selTokensByPar = selTokens.groupBy({ it.first }, { it.second })
    val initExamples = selTokensByPar.keys.sorted().map { iPar ->
        val par = translationResult.srcChapter.paragraphs[iPar]
        val trPar = translationResult.dstChapter.paragraphs[iPar]
        Example(par.content, trPar.content, ExampleSource(Id(chapterId!!), iPar))
    }
    return initExamples
}

external interface AddExamplesDialogProps: Props {
    var wordKey: String
    var onClose: (Map<String,Set<Int>>) -> Unit
    var entries: List<WordEntry>
    var examples: List<Example>
    var selSets: Map<String,Set<Int>>
}

private val AddExamplesDialog = FC<AddExamplesDialogProps> { props ->
    var selSetByEntry by useState<Map<String,Set<Int>>>(props.selSets)
    var selEntryId by useState(props.entries[0].id!!)
    Dialog {
        open = true
        onClose = {_,_ -> props.onClose(selSetByEntry) }
        DialogContent {
            Stack {
                direction = responsive(StackDirection.row)
                List {
                    props.entries.forEach { entry ->
                        ListItemButton {
                            sx {
                                backgroundColor = if (entry.id == selEntryId) NamedColor.lightblue else null
                            }
                            onClick = { selEntryId = entry.id!! }
                            ListItemText {
                                primary = ReactNode(entry.translations.joinToString())
                            }
                        }
                    }
                }
                ReactHTML.div {
                    val selSet = selSetByEntry.getOrElse(selEntryId) { emptySet() }
                    props.examples.forEachIndexed { i, ex ->
                        val isSelected = i in selSet
                        ExampleComponent {
                            example = ex
                            selected = isSelected
                            onSelect = {
                                selSetByEntry += selEntryId to (if(isSelected) selSet-i else selSet+i)
                            }
                        }
                    }
                }
            }
        }
        DialogActions {
            Button {
                onClick = { props.onClose(selSetByEntry) }
                + "Close"
            }
        }
    }
}