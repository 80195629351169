package pages

import OutletContext
import anyword.model.Id
import anyword.model.Share
import anyword.model.Shares
import anyword.model.UserInfo
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.coroutines.launch
import mui.icons.material.Menu
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.html.ReactHTML
import react.router.useNavigate
import react.router.useOutletContext
import web.cssom.number

val SharedPage = FC<Props> {
    val context = useOutletContext<OutletContext>()
    AppBar {
        //css { maxWidth = 40.em }
        position = AppBarPosition.fixed
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                color = IconButtonColor.inherit
                onClick = { context.openDrawer() }
                Menu()
            }
            Typography {
                variant = TypographyVariant.h6
                sx { flexGrow = number(1.0) }
                +"Shared"
            }
        }
    }
    Toolbar {}

    var tabIndex by useState(0)
    Tabs {
        value = tabIndex
        onChange = { _, v -> tabIndex = v }
        Tab { label = ReactNode("Incoming") }
        Tab { label = ReactNode("Outgoing") }
    }
    ReactHTML.div {
        hidden = tabIndex != 0
        IncomingTab {
            titleRender = { b,share,userMap -> b.run {
                val fullName = userMap[share.owner]?.let { it.fullName ?: it.name }
                 + "From $fullName"
            } }
        }
    }
    ReactHTML.div {
        hidden = tabIndex != 1
        OutgoingTab {
            titleRender = { b,share,userMap -> b.run {
                val names = share.users.map { u -> userMap[u]?.let { it.fullName ?: it.name } }
                + "To ${names.joinToString()}"
            } }
        }
    }
}

typealias TitleRender = (ChildrenBuilder,Share,Map<String,UserInfo>) -> Unit

external interface SharedTabProps: Props {
    var titleRender: TitleRender
}

val IncomingTab = FC<SharedTabProps> { props ->
    val nav = useNavigate()
    var shares by useState<Shares?>(null)
    useEffectOnce {
        mainScope.launch {
            val resp = client.get("$apiUrl/shares/incoming")
            shares = resp.body()
        }
    }
    // shares
    val s = shares
    if (s != null) {
        ShareList {
            this.shares = s
            titleRender = props.titleRender
            onClick = { id -> nav.invoke(id.hex) }
        }
    } else {
        CircularProgress {}
    }
}

val OutgoingTab = FC<SharedTabProps> { props ->
    val nav = useNavigate()
    var shares by useState<Shares?>(null)
    useEffectOnce {
        mainScope.launch {
            val resp = client.get("$apiUrl/shares/outgoing")
            shares = resp.body()
        }
    }
    // shares
    val s = shares
    if (s != null) {
        ShareList {
            this.shares = s
            titleRender = props.titleRender
            onClick = { id -> nav.invoke(id.hex) }
        }
    } else {
        CircularProgress {}
    }
}

external interface ShareListProps: SharedTabProps {
    var shares: Shares
    var onClick: (Id) -> Unit
}

val ShareList = FC<ShareListProps> {props ->
    val s = props.shares
    val ownerMap = s.users.associateBy { it.id }
    val wordMap = s.words.associateBy { it.id }
    List {
        s.list.forEach {share ->
            ListItem {
                ListItemButton {
                    ListItemText {
                        props.titleRender(this, share, ownerMap)
                        secondary = ReactNode(share.words.mapNotNull { id -> wordMap.get(id)?.word }.joinToString())
                    }
                    onClick = {
                        props.onClick(share.id!!)
                    }
                }
            }
        }
    }
}
