package components

import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.launch
import mui.icons.material.MoreVert
import mui.material.*
import mui.material.styles.TypographyVariant
import pages.apiUrl
import pages.client
import pages.mainScope
import react.FC
import react.Props
import react.useState
import web.dom.Element

external interface WordsCloneSelectionMenuProps: Props {
    var selIds: Set<String>
    var refresh: (Boolean, String) -> Unit
}

val WordsCloneSelectionMenu = FC<WordsCloneSelectionMenuProps> { props ->
    var menuAnchor by useState<Element?>(null)
    if (props.selIds.isNotEmpty()) {
        Typography {
            variant = TypographyVariant.h6
            +"(${props.selIds.size})"
        }
        IconButton {
            edge = IconButtonEdge.end
            color = IconButtonColor.inherit
            onClick = { ev -> menuAnchor = ev.currentTarget }
            MoreVert()
        }
        Menu {
            open = menuAnchor != null
            anchorEl = { menuAnchor.asDynamic() }
            onClose = { menuAnchor = null }

            MenuItem {
                +"Clone"
                onClick = {
                    menuAnchor = null
                    mainScope.launch {
                        val res = client.post("$apiUrl/words/clone") {
                            contentType(ContentType.Application.Json)
                            setBody(props.selIds.toList())
                        }
                        if (res.status.isSuccess()) {
                            props.refresh(true, res.bodyAsText())
                        } else {
                            props.refresh(false, res.status.description)
                        }
                    }
                }
            }
        }
    }
}
