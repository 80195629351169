package anyword.model

import kotlinx.datetime.Instant
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
class ExampleSource(@Contextual val chapterId: Id, val iPar: Int)

@Serializable
class Example(val original: String, val translation: String, val source: ExampleSource? = null)

@Serializable
enum class LearnStatus {
    None, Active, Done, Ignore
}

@Serializable
class Learn(val status: LearnStatus,
            @Contextual val nextReviewTime: Instant?, // Contextual so that InstantSerializer is picked for MongoDB
            val reviewCount: Int? = null,
            val forgotCount: Int? = null) {

    fun incForgot(): Learn {
        val forgotCount = (forgotCount ?: 0) + 1
        return Learn(status, nextReviewTime, reviewCount, forgotCount)
    }
}

@Serializable
class WordEntry (
    var userId: String,
    var word: String,
    var transcription: String?,
    var translations: List<String>,
    var examples: List<Example>?,
    var learn: Learn = Learn(LearnStatus.None, null, 0),
    var fromId: String? = null,
    var freq: Int? = null, // 1..10
    @SerialName("_id") var id: String? = null,
) {
    override fun toString(): String {
        return "WordEntry($word, $transcription, $translations)"
    }
}

