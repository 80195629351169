package pages

import OutletContext
import anyword.model.Source
import anyword.model.SourceType
import emotion.react.css
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.icons.material.Menu
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.onChange
import react.router.useNavigate
import react.router.useOutletContext
import web.cssom.em
import web.cssom.number
import web.cssom.px

val SourcesPage = FC<Props> {
    val context = useOutletContext<OutletContext>()
    val nav = useNavigate()
    AppBar {
        //css { maxWidth = 40.em }
        position = AppBarPosition.fixed
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                color = IconButtonColor.inherit
                onClick = { context.openDrawer() }
                Menu()
            }
            Typography {
                variant = TypographyVariant.h6
                sx { flexGrow = number(1.0) }
                +"Sources"
            }
        }
    }
    Toolbar {}

    var sources by useState<List<Source>?>(null)
    useEffectOnce {
        mainScope.launch {
            val resp = client.get("$apiUrl/sources")
            sources = resp.body()
        }
    }
    val list = sources
    if (list != null) {
        List {
            for (source in list) {
                ListItem {
                    ListItemButton {
                        onClick = {
                            nav.invoke(source.id!!.hex)
                        }
                        ListItemText {
                            primary = ReactNode(source.title)
                        }
                    }
                }
            }
        }
    }
    var showNewDialog by useState(false)
    Fab {
        color = FabColor.primary
        Icon {
            Add()
            onClick = {
                showNewDialog = true
            }
        }
    }
    var sourceType by useState(SourceType.TvSeries)
    var sourceTitle by useState("")
    Dialog {
        open = showNewDialog
        DialogTitle {
            + "New source"
        }
        DialogContent {
            Stack {
                css { padding = 10.px }
                TextField {
                    select = true
                    label = ReactNode("Type")
                    value = sourceType
                    onChange = { ev -> sourceType = ev.target.asDynamic().value }
                    SourceType.entries.forEach {
                        MenuItem {
                            value = it
                            + it.name
                        }
                    }
                }
                TextField {
                    label = ReactNode("Title")
                    sx { minWidth = 10.em }
                    value = sourceTitle
                    onChange = { sourceTitle = it.target.asDynamic().value }
                }
            }
        }
        DialogActions {
            Button {
                + "Close"
                onClick = { showNewDialog = false }
            }
            Button {
                variant = ButtonVariant.contained
                disabled = sourceTitle.isBlank()
                + "Create"
                onClick = {
                    mainScope.launch {
                        val res = client.post("$apiUrl/sources/new") {
                            contentType(ContentType.Application.Json)
                            setBody(Source(sourceType, sourceTitle))
                        }
                        if (res.status.isSuccess()) {
                            val newId: String = res.body()
                            sourceTitle = ""
                            showNewDialog = false
                            nav.invoke(newId)
                        }
                    }
                }
            }
        }
    }
}