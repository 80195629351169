package pages

import OutletContext
import UserContext
import anyword.db.UserNotifications
import anyword.model.SubsResult
import components.UserSelectDialog
import firebase.app.initializeApp
import firebase.getMessaging
import firebase.getToken
import firebase.onMessage
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import js.core.jso
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mui.icons.material.AccountCircle
import mui.icons.material.Menu
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import org.w3c.notifications.GRANTED
import org.w3c.notifications.Notification
import org.w3c.notifications.NotificationPermission
import react.*
import react.dom.html.ReactHTML.div
import react.dom.onChange
import react.router.useOutletContext
import web.cssom.*

// TODO: Replace the following with your app's Firebase project configuration
val firebaseConfig = jso<dynamic> {
    projectId = "anyword-393008"
    appId = "1:617840153483:web:b904b633990bca25b2958e"
    apiKey = "AIzaSyDasFaiP_VYGprZfvMCbSbpCNfOXAtthgo"
    messagingSenderId = "617840153483"
}
val firebaseApp = initializeApp(firebaseConfig);
val messaging = getMessaging().also {
    console.log("getMessaging()")
    onMessage(it) { payload ->
        console.log("Message received. ", payload);
        // Update the UI to include the received message.
        //appendMessage(payload);
    };
}

private const val VAPID_KEY = "BLi2d9wLFQrKI5UdttvxTXw_C33AKFauIJDMEBb-EFIE8Ua9E2wuS37AsyRCrFcjUmoPhU1ib3SLrEAQg3HnGA0"

val ProfilePage = FC<Props> {
    val context = useOutletContext<OutletContext>()
    var snackMessage by useState<String?>(null)
    var snackError by useState<Boolean>(false)

    AppBar {
        //css { maxWidth = 40.em }
        position = AppBarPosition.fixed
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                color = IconButtonColor.inherit
                onClick = { context.openDrawer() }
                Menu()
            }
            Typography {
                variant = TypographyVariant.h6
                sx { flexGrow = number(1.0) }
                +"Profile"
            }
        }
    }
    Toolbar {}

    var tabIndex by useState(0)
    Tabs {
        value = tabIndex
        onChange = { _,v -> tabIndex = v }
        Tab { label = ReactNode("Personal") }
        Tab { label = ReactNode("Notifications") }
        Tab { label = ReactNode("Subscriptions") }
    }
    div {
       hidden = tabIndex != 0
        PersonalTab {}
    }
    div {
        hidden = tabIndex != 1
        NotificationsTab {
            onResult = { m,r ->
                snackMessage = m
                snackError = r
            }
        }
    }
    div {
        hidden = tabIndex != 2
        SubscriptionsTab {
            onResult = { m,r ->
                snackMessage = m
                snackError = r
            }
        }
    }
    Snackbar {
        open = snackMessage != null
        message = ReactNode(snackMessage)
        autoHideDuration = 6000
        onClose = { _,_ ->
            snackMessage = null
        }
        Alert {
            severity = if(snackError) AlertColor.error else AlertColor.success
            +snackMessage
        }
    }
}

external interface TabProps: Props {
    var onResult: (String?,Boolean) -> Unit
}

private val PersonalTab = FC<TabProps> {
    val context = useContext(UserContext)
    Typography {
        + context?.info?.name
    }
    Typography {
        + context?.info?.fullName
    }
}

private val NotificationsTab = FC<TabProps> { props ->
    var permission by useState(Notification.permission)
    var config by useState<UserNotifications.Config?>(null)
    useEffectOnce {
        mainScope.launch {
            val res = client.get("$apiUrl/notification/config")
            config = res.body()
        }
    }

    val cfg = config
    if (cfg != null) {
        FormGroup {
            sx { alignItems = AlignItems.flexStart; gap = 5.px; marginTop = 1.em; flexDirection = FlexDirection.row }
            TextField {
                sx { minWidth = 5.em }
                label = ReactNode("Hour")
                select = true
                value = cfg.hour
                onChange = { ev -> config = cfg.copy(hour = ev.target.asDynamic().value) }
                (0..23).forEach {
                    MenuItem {
                        val v = it.toString().padStart(2, '0')
                        value = it
                        +v
                    }
                }
            }
            TextField {
                sx { minWidth = 5.em }
                label = ReactNode("Count")
                select = true
                value = cfg.count
                onChange = { ev -> config = cfg.copy(count = ev.target.asDynamic().value) }
                (1..4).forEach {
                    MenuItem {
                        value = it
                        +it.toString()
                    }
                }
            }
            TextField {
                sx { minWidth = 5.em }
                label = ReactNode("Period (h)")
                select = true
                value = cfg.period
                onChange = { ev -> config = cfg.copy(period = ev.target.asDynamic().value) }
                (2..12).forEach {
                    MenuItem {
                        value = it
                        +it.toString()
                    }
                }
            }
            Button {
                onClick = {
                    mainScope.launch {
                        val res = client.post("$apiUrl/notification/config") {
                            contentType(ContentType.Application.Json)
                            val tz = js("Intl.DateTimeFormat().resolvedOptions().timeZone")
                            setBody(config!!.copy(timeZone = tz))
                        }
                        if (res.status.isSuccess()) {
                            props.onResult("Config Saved", false)
                        } else {
                            props.onResult(res.status.description, true)
                        }
                    }
                }
                +"Save"
            }
        }
    }

    Button {
        //disabled = permission==NotificationPermission.GRANTED
        variant = ButtonVariant.outlined
        +"Enable notifications"
        onClick = {_ ->
            val permPromise = Notification.requestPermission()
            permPromise.then {
                permission = it
                if (it == NotificationPermission.GRANTED) {
                    getToken(messaging, jso<dynamic> {
                        vapidKey = VAPID_KEY
                    }).then { tk ->
                        mainScope.launch {
                            val res = client.post("$apiUrl/notification/set") {
                                parameter("token", tk)
                            }
                            if (res.status.isSuccess()) {
                                props.onResult("Updated token ${tk.toString().take(8)}..", false)
                            } else {
                                props.onResult(res.status.description, true)
                            }
                        }
                    }.catch {
                        props.onResult(it.message, true)
                    }
                }
            }
        }
    }
    + Notification.permission.toString()
    Button {
        variant = ButtonVariant.outlined
        +"Test (after 5s)"
        onClick = { _ ->
            mainScope.launch {
                delay(5000)
                val res = client.post("$apiUrl/notification/test")
                props.onResult(res.status.description, !res.status.isSuccess())
            }
        }
    }
    Typography {
        variant = TypographyVariant.h5
        + "Subscriptions"
    }
}

private val SubscriptionsTab = FC<TabProps> { props ->
    var refresh by useState(0)
    var subs by useState<SubsResult?>(null)
    var subOpen by useState(false)
    useEffect(refresh) {
        mainScope.launch {
            subs = client.get("$apiUrl/notification/subs").body<SubsResult>()
        }
    }

    if (subs != null) {
        val obj = subs!!
        List {
            for (sub in obj.list) {
                ListItem {
                    ListItemAvatar { AccountCircle() }
                    ListItemText {
                        key = sub.userId
                        + obj.users.getValue(sub.userId)
                    }
                }
            }
        }
        Button {
            + "Add"
            onClick = {
                subOpen = true
            }
        }
    }
    UserSelectDialog {
        title = "Subscribe to user"
        action = "Subscribe"
        open = subOpen
        onClose = { subOpen = false }
        onSelect = {
            mainScope.launch {
                val resp = client.post("$apiUrl/notification/subs") {
                    contentType(ContentType.Application.Json)
                    setBody(it.id)
                }
                subOpen = false
                refresh += 1
                if (!resp.status.isSuccess()) {
                    props.onResult(resp.status.description, true)
                }
            }
        }
    }
}