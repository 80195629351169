package pages

import UserContext
import UserContextProps
import anyword.model.UploadResult
import components.LoginIndicator
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import io.ktor.utils.io.js.writeFully
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import mui.icons.material.Menu
import mui.icons.material.UploadFile
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import org.khronos.webgl.ArrayBuffer
import react.*
import react.dom.events.ChangeEvent
import react.dom.html.ReactHTML.input
import react.router.useOutletContext
import web.cssom.number
import web.html.HTMLInputElement
import web.html.InputType

val UploadPage = FC<Props> {
    val context = useOutletContext<LearnContext>()
    val userContext = useContext(UserContext)!!
    AppBar {
        //css { maxWidth = 40.em }
        position = AppBarPosition.fixed
        Toolbar {
            IconButton {
                edge = IconButtonEdge.start
                color = IconButtonColor.inherit
                onClick = { context.openDrawer() }
                Menu()
            }
            Typography {
                variant = TypographyVariant.h6
                sx { flexGrow = number(1.0) }
                +"Upload"
            }
            LoginIndicator {}
        }
    }
    Toolbar {}

    var uploadProgress by useState(0)
    var uploadResult by useState<Pair<UploadResult?,String>?>(null)
    Button {
        variant = ButtonVariant.outlined
        startIcon = Icon.create { UploadFile() }
        this.asDynamic().component = "label"
        + "Upload re-word/fb2"
        input {
            type = InputType.file
            hidden = true
            accept = ".txt,.fb2,.srt"
            onChange = {
                handleFileUpload(it, userContext,
                    { p: Int -> uploadProgress = p },
                    { res, err ->
                        uploadResult = if (err != null) {
                            null to err
                        } else {
                            res to "Success"
                        }
                    })
            }
        }
    }
    LinearProgress {
        variant = LinearProgressVariant.determinate
        value = uploadProgress
    }
    Snackbar {
        open = uploadResult != null
        autoHideDuration = 6000
        onClose = { _,_ -> uploadResult = null; uploadProgress = 0 }
        Alert {
            val res = uploadResult?.first
            if (res != null) {
                severity = AlertColor.success
                +"${res.insertCount} inserted, ${res.skipCount} skipped"
            } else {
                severity = AlertColor.error
                +uploadResult?.second
            }
        }
    }
}

fun handleFileUpload(ev: ChangeEvent<HTMLInputElement>, userContext: UserContextProps, progressFun: (Int) -> Unit,
                     doneFun: (UploadResult?, String?) -> Unit) {
    val files = ev.target.files ?: return
    val file = files[0]
    mainScope.launch {
        val data = file.arrayBuffer().await()
        val type = when {
            file.name.endsWith(".txt") -> "csv"
            file.name.endsWith(".srt") -> "srt"
            file.name.endsWith(".fb2") -> "fb2"
            else -> "reword"
        }
        val res = client.post("$apiUrl/upload/$type") {
            header("Content-Length", data.byteLength)
            setBody(MultiPartFormDataContent(
                formData {
                    append("file", file.name, bodyBuilder = {
                        this.writeFully(data.asDynamic() as ArrayBuffer) // it is the same js class
                    })
                }
            ))
            onUpload { bytesSentTotal, _ ->
                progressFun((bytesSentTotal*100/data.byteLength).toInt())
            }
        }
        if (res.status.isSuccess()) {
            doneFun(res.body(), null)
        } else {
            doneFun(null, res.status.description)
        }
    }
}