package anyword.model

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.time.Duration

enum class SourceType {
    TvSeries,
    Book,
    Article,
    Movie
}

@Serializable
class Source (
    var type: SourceType,
    var title: String, // e.g. Friends
    @SerialName("_id") @Contextual var id: Id? = null
)

@Serializable
class SourceParagraph(
    var content: String,
    var time: Duration? = null
) {
    override fun toString(): String {
        return "SourceParagraph($content, $time)"
    }
}

@Serializable
class ChapterTranslation(
    @Contextual var originId: Id,
    var provider: String,
    var lang: String
)

@Serializable
class SourceChapter(
    @Contextual var sourceId: Id,
    var part: Int, // Part for Book, Season for TvSeries
    var number: Int?, // Section for Book, Episode for TvSeries
    var title: String?,
    var paragraphs: List<SourceParagraph>,
    var translation: ChapterTranslation? = null,
    @SerialName("_id") @Contextual var id: Id? = null
) {
    override fun toString(): String {
        return "SourceChapter($sourceId, $part, $number, ${paragraphs.size})"
    }
}

@Serializable
class RestSource(
    val source: Source,
    val chapters: List<RestChapter>
)

@Serializable
class RestChapter(
    var part: Int, // Part for Book, Season for TvSeries
    var number: Int?, // Section for Book, Episode for TvSeries
    var title: String?,
    @SerialName("_id") @Contextual var id: Id? = null
)

fun SourceChapter.toRest(): RestChapter = RestChapter(part, number, title, id)