package components

import UserContext
import mui.icons.material.*
import mui.material.*
import mui.material.List
import react.*
import react.router.useNavigate

class Item(val name: String, val link: String, val icon: SvgIconComponent?)

external interface DrawerItemProps: Props {
    var item: Item
    var close: () -> Unit
}
external interface MyDrawerProps: Props {
    var isOpen: Boolean
    var close: () -> Unit
}

val DrawerItem = FC<DrawerItemProps> { props ->
    val item = props.item
    val nav = useNavigate()
    ListItem {
        ListItemButton {
            onClick = { nav.invoke(item.link); props.close() }
            item.icon?.let{ iconComp ->
                ListItemIcon {
                    iconComp()
                }
            }
            ListItemText {
                primary = ReactNode(item.name)
            }
        }
    }
}
val MyDrawer = FC<MyDrawerProps> { props ->
    val userContext = useContext(UserContext)
    val items = listOf(
        Item("Home", "/", Home)
    )
    val authItems =
        if (userContext?.info==null) emptyList()
        else listOf(
            Item("Words", "/words", LibraryBooks),
            Item("Learn", "/learn", ModelTraining),
            Item("Events", "/events", RssFeed),
//            Item("Shared", "/shared", FolderShared),
            Item("Sources", "/sources", LocalMovies),
            Item("Profile", "/profile", AccountCircle),
            Item("Upload", "/upload", UploadFile)
        )
    Drawer {
        anchor = DrawerAnchor.left
        open = props.isOpen
        onClose = {_,_ -> props.close() }
        List {
            for (item in items+authItems) {
                DrawerItem {
                    this.item = item
                    this.close = props.close
                }
            }
        }
    }
}
