package anyword.model

import kotlinx.serialization.Serializable

@Serializable
class WordsResult(
    val total: Int,
    val list: List<WordEntry>,
    val shares: Shares? = null
)
