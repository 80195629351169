package components

import UserContext
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.coroutines.launch
import mui.icons.material.AccountCircle
import mui.material.*
import pages.apiUrl
import pages.client
import pages.mainScope
import react.FC
import react.Props
import react.router.useNavigate
import react.router.useNavigation
import react.useContext
import react.useState
import web.dom.Element

val LoginIndicator = FC<Props> {
    val userContext = useContext(UserContext)
    var menuAnchor by useState<Element?>(null)
    val nav = useNavigate()
    val info = userContext?.info
    if (info != null) {
        IconButton {
            size = Size.large
            color = IconButtonColor.inherit
            onClick = { ev -> menuAnchor = ev.currentTarget }
            AccountCircle()
        }
        Menu {
            open = menuAnchor != null
            anchorEl = { menuAnchor.asDynamic() }
            onClose = { menuAnchor = null }
            MenuItem {
                +"Logout"
                onClick = {
                    mainScope.launch {
                        val res = client.post("$apiUrl/logout")
                        menuAnchor = null
                        if (res.status.isSuccess()) {
                            userContext.setInfo(null)
                            nav.invoke("/")
                        }
                    }
                }
            }
        }
    }
}