package pages

import OutletContext
import UserContext
import anyword.db.commonModule
import anyword.model.*
import components.LoginIndicator
import components.UserSelectDialog
import emotion.react.css
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.coroutines.*
import kotlinx.datetime.Clock
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import mui.icons.material.Add
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.*
import react.dom.html.ReactHTML
import react.dom.onChange
import react.router.Outlet
import react.router.useLocation

import react.router.useNavigate
import react.router.useOutletContext
import web.cssom.*
import web.dom.Element
import web.dom.document

val mainScope = MainScope()
val json = Json {
    serializersModule = SerializersModule {
        contextual(Id::class, IdStringSerializer)
        include(commonModule)
    }
}
val client = HttpClient() {
    install(ContentNegotiation) {
        json(json)
    }
}

val apiUrl = if (document.URL.contains("localhost")) "/api" else "/api"

private val perPage = 50

val WordsPage = FC<Props> {
    val ctx = useOutletContext<OutletContext>()
    val location = useLocation();
    var result by useState<WordsResult?>(null)
    var refresh by useState(0)
    var filterOpen by useState(false)
    var filters by useState(Filters())
    var selIds by useState(emptySet<String>())
    var findValue by useState("")
    val context = useOutletContext<OutletContext>()
    val nav = useNavigate()
    var curPage by useState(0)

    useEffect(filters, curPage, refresh, findValue) {
        mainScope.launch {
            val resp = client.get("$apiUrl/words") {
                parameter("skip", curPage* perPage)
                parameter("limit", perPage)
                if (filters.status != null) parameter("status", filters.status)
                if (findValue.isNotBlank()) parameter("find", findValue)
            }
            if (resp.status == HttpStatusCode.OK) {
                result = resp.body<WordsResult>()
            } else {
                nav("/")
            }
        }
    }

    if (location.pathname.endsWith("words")) {
        val res = result
        Fragment {
            AppBar {
                //css { maxWidth = 40.em }
                position = AppBarPosition.fixed
                Toolbar {
                    IconButton {
                        edge = IconButtonEdge.start
                        color = IconButtonColor.inherit
                        onClick = { context.openDrawer()  }
                        mui.icons.material.Menu()
                    }
                    Typography {
                        variant = TypographyVariant.h6
                        sx { flexGrow = number(1.0) }
                        +"Words"
                    }
                    TextField {
                        variant = FormControlVariant.outlined
                        label = ReactNode("Find..")
                        sx {
                            backgroundColor = rgb(255, 255, 255)
                            flexGrow = number(1.0)
                        }
                        value = findValue
                        onChange = { findValue = it.target.asDynamic().value }
                    }
                    WordsSelectionMenu {
                        this.selIds = selIds
                        this.refresh = {
                            selIds = emptySet()
                            refresh += 1
                        }
                    }
                    IconButton {
                        color = IconButtonColor.inherit
                        onClick = { filterOpen = true }
                        mui.icons.material.FilterAlt()
                    }
                    LoginIndicator {}
                }
            }
            Toolbar {} // second toolbar: one of 3 hacks to avoid hiding the toolbar in fixed placement
        }
        if (filterOpen) {
            FilterDialog {
                open = filterOpen
                onClose = {
                    if (it != null) {
                        filters = it
                        curPage = 0
                    }
                    filterOpen = false
                }
                initFilters = filters
            }
        }
        val wordSharesMap = result?.shares?.list?.flatMap { it.words.map { w-> w to it} }?.groupBy({it.first}, {it.second}) ?: emptyMap()
        val usersMap = result?.shares?.users?.associateBy { it.id } ?: emptyMap()
        List {
            dense = true
            for (entry in result?.list ?: emptyList()) {
                ListItem {
                    key = entry.id
                    disablePadding = true
                    ListItemIcon {
                        Checkbox {
                            //edge = SwitchBaseEdge.start
                            checked = entry.id in selIds
                            onClick = {e ->
                                if (entry.id in selIds) selIds -= entry.id!!
                                else selIds += entry.id!!
                            }
                        }
                    }
                    ListItemIcon {
                        sx { minWidth = 36.px }
                        LearnProgress { this.entry = entry }
                    }
                    ListItemButton {
                        dense = true
                        onClick = { nav.invoke("${entry.id}") }
                        ListItemText {
                            sx { margin = 0.px }
                            primary = Fragment.create {
                                +entry.word
                                if (entry.freq != null) {
                                    LinearProgress {
                                        variant = LinearProgressVariant.determinate
                                        value = entry.freq!! * 10
                                        title = "Usage ${entry.freq!!}/10"
                                        sx {
                                            width = 2.em
                                            height = 0.5.em
                                            marginLeft = 0.5.em
                                            display = Display.inlineBlock
                                        }
                                    }
                                }
                            }
                            secondary = Fragment.create {
                                + entry.translations.joinToString(", ")
                            }
                        }
                    }
                    ListItemSecondaryAction {
                        val shareList = wordSharesMap[entry.id]
                        if (shareList != null) {
                            Stack {
                                direction = responsive(StackDirection.row)
                                shareList.forEach { share ->
                                    share.users.forEach { userId ->
                                        val user = usersMap.get(userId)
                                        val initials = initials(user)
                                        val name = user?.fullName ?: user?.name
                                        Avatar {
                                            +initials
                                            alt = name
                                            title = name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (res!=null && res.list.isNotEmpty()) {
                Pagination {
                    count = (res.total+ perPage-1) / perPage
                    page = curPage+1
                    onChange = {_,n -> curPage = n.toInt()-1}
                    //color = PaginationColor.primary
                }
            }
        }
        ReactHTML.span {
            css {
                position = Position.fixed
                right = 20.px
                bottom = 20.px
            }
            Fab {
                color = FabColor.primary
                Icon {
                    Add()
                }
                onClick = { nav.invoke("new") }
            }
        }
    } else {
        Outlet {
            this.context = ctx
        }
    }
}

fun initials(user: UserInfo?): String {
    val name = user?.fullName ?: user?.name ?: "?"
    return name.split(" ").take(2).map { it[0] }.joinToString("")
}

external interface LearnProgressProps: Props {
    var entry: WordEntry
}

val LearnProgress = FC<LearnProgressProps> { props ->
    val learn = props.entry.learn
    when (learn.status) {
        LearnStatus.Active -> {
            Badge {
                color = BadgeColor.secondary
                overlap = BadgeOverlap.circular
                badgeContent = ReactNode("${learn.reviewCount}")
                Icon {
                    //css { color = NamedColor.yellow }
                    fontSize = IconSize.medium
                    mui.icons.material.ModelTraining()
                }
            }
        }
        LearnStatus.Done -> {
            Icon {
                css { color = NamedColor.green }
                fontSize = IconSize.medium
                mui.icons.material.CheckCircle()
            }
        }
        LearnStatus.Ignore -> {
            Icon {
                css { color = NamedColor.red }
                fontSize = IconSize.medium
                mui.icons.material.Block()
            }
        }
        else -> {}
    }
}

class Filters (
    val status: LearnStatus? = null
)

external interface FilterDialogProps: Props {
    var open: Boolean
    var onClose: (Filters?) -> Unit
    var initFilters: Filters
}

private val FilterDialog = FC<FilterDialogProps> { props ->
    var learnStatus by useState<LearnStatus?>(props.initFilters.status)
    Dialog {
        open = props.open
        onClose = { _,_ -> props.onClose(null) }
        DialogTitle { + "Filters" }
        FormControl {
            fullWidth = true
            Select {
                value = learnStatus?.name ?: ""
                onChange = { ev, _ ->
                    learnStatus = ev.target.value.ifBlank { null }?.let { LearnStatus.valueOf(it) }
                }
                MenuItem {
                    value = ""
                    + "-"
                }
                LearnStatus.values().forEach {
                    MenuItem {
                        value = it.name
                        + it.name
                    }
                }
            }
        }
        DialogActions {
            Button {
                + "Apply"
                onClick = { props.onClose(Filters(learnStatus)) }
            }
            Button {
                + "Cancel"
                onClick = { props.onClose(null) }
            }
        }
    }
}

private external interface WordsSelectionMenuProps: Props {
    var selIds: Set<String>
    var refresh: () -> Unit
}

private val WordsSelectionMenu = FC<WordsSelectionMenuProps> { props ->
    val userContext = useContext(UserContext)
    var menuAnchor by useState<Element?>(null)
    var shareOpen by useState(false)
    if (props.selIds.isNotEmpty()) {
        Typography {
            variant = TypographyVariant.h6
            + "(${props.selIds.size})"
        }
        IconButton {
            edge = IconButtonEdge.end
            color = IconButtonColor.inherit
            onClick = { ev -> menuAnchor = ev.currentTarget }
            mui.icons.material.MoreVert()
        }
        Menu {
            open = menuAnchor!=null
            anchorEl = { menuAnchor.asDynamic() }
            onClose = { menuAnchor = null }
            MenuItem {
                + "Add to Learn"
                onClick = {
                    menuAnchor = null
                    mainScope.launch {
                        val resp = client.post("$apiUrl/words/learn/add") {
                            // header("user_session", userContext.info!!.session)
                            props.selIds.forEach { parameter("id", it) }
                        }
                        val s = resp.status
                        if (s == HttpStatusCode.OK) {
                            props.refresh()
                        }
                    }
                }
            }
            MenuItem {
                + "Mark as Done"
                onClick = {
                    menuAnchor = null
                    mainScope.launch {
                        val resp = client.post("$apiUrl/words/learn/done") {
                            props.selIds.forEach { parameter("id", it) }
                        }
                        val s = resp.status
                        if (s == HttpStatusCode.OK) {
                            props.refresh()
                        }
                    }
                }
            }
            MenuItem {
                +"Share"
                onClick = {
                    menuAnchor = null
                    shareOpen = true
                }
            }
            MenuItem {
                +"Update"
                onClick = {
                    menuAnchor = null
                    mainScope.launch {
                        val resp = client.post("$apiUrl/words/update") {
                            props.selIds.forEach { parameter("id", it) }
                        }
                        val s = resp.status
                        if (s == HttpStatusCode.OK) {
                            props.refresh()
                        }
                    }
                }
            }
        }
        UserSelectDialog {
            title = "Share words"
            action = "Share"
            open = shareOpen
            onClose = { shareOpen = false }
            onSelect = { u ->
                mainScope.launch {
                    client.post("$apiUrl/shares/new") {
                        contentType(ContentType.Application.Json)
                        setBody(Share(userContext!!.info!!.id, Clock.System.now(), listOf(u.id), props.selIds.toList()))
                    }
                    shareOpen = false
                    props.refresh()
                }
            }
        }
    }
}